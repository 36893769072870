<template>
  <h2>{{ title }}</h2>
</template>

<script>
export default {
  name: "SubTitle",
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";
h2 {
  text-transform: uppercase;
  text-align: center;
  font-family: timesnow, serif;
  font-size: 34px;
  line-height: 32px;
  letter-spacing: 0em;
  margin: 32px 0;
  @include desktop {
    font-size: 105px;
    line-height: 80px;
    letter-spacing: -0.03em;
    margin: 80px 0;
  }
}
</style>