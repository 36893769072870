import gql from 'graphql-tag'

export const GET_CATEGORY = gql`
  query Category($slug: String!) {
    category:categories (where: { slug: $slug }) {
      name
      articles {
        id
        created_at
        title
        subtitle
        slug
        start
        end
        cover_hide
        credits {
          id
          contribution
          contributors {
            id
            name
            url
            agency
            agencyUrl
          }
        }
        cover {
          id
          alternativeText
          url
          width
          height
          formats
        }
      }
    }
  }
`