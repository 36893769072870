<template>
  <div id="selection" v-if="selections">
    <PageTitle title="Selection" />
    <div class="selections-list">
      <a
        class="selection-entry"
        v-for="item in selections"
        :key="'selection-' + item.id"
        :href="'/selection/' + item.slug"
      >
        <div class="selection-cover">
          <div class="imagewrap">
            <GetImage
              v-if="item.slider.slides[0]"
              :image="item.slider.slides[0].image"
              size="medium"
            />
          </div>
        </div>
        <div class="selection-title">
          {{ item.Name }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { GET_SELECTIONS } from "@/graphql/queries/getSelections.js";
import PageTitle from "@/components/PageTitle.vue";
import GetImage from "@/components/GetImage.vue";

export default {
  name: "Selections",
  data() {
    return {};
  },
  apollo: {
    selections: {
      query: GET_SELECTIONS
    }
  },
  components: {
    PageTitle,
    GetImage
  }
}

</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

#selection {
  padding: 0 10px;
  font-weight: 300;
  .selections-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    @include desktop {
      margin: 0 -20px;
    }
    .selection-entry {
      width: 100%;
      margin: 40px 0;
      text-decoration: none;
      font-weight: 300;
      font-size: 36px;
      line-height: 32px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      @include desktop {
        width: 50%;
        padding: 10px;
        font-size: 90px;
        line-height: 85%;
        letter-spacing: 0.01em;
      }
      &:hover,
      &:active {
        color: var(--current-color);
        .imagewrap {
          background: var(--current-color);
        }
        img {
          filter: grayscale(100%);
          mix-blend-mode: multiply;
        }
      }
      .selection-cover {
        height: calc(calc(50vw - 15px) * 1.3333);
        margin-bottom: 3px;
        position: relative;
        @include desktop {
          height: calc(calc(25vw - 12.5px) * 1.3333);
          margin-bottom: 5px;
        }
        .imagewrap {
          max-height: 100%;
          overflow: hidden;
        }
        img {
          max-height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center left;
        }
      }
      .selection-title {
        @include desktop {
          padding: 0 10px;
        }
      }
    }
  }
}
</style>
