<template>
  <div class="searchform">
    <form
      @submit="goSearch"
      @click="searchclick"
      :class="searchterm != '' ? '' : 'empty'"
    >
      <label for="search">Search magazine.studio:</label>
      <input
        v-model="searchterm"
        type="text"
        id="search"
        name="search"
        @focus="$el.classList.add('focus')"
        @blur="$el.classList.remove('focus')"
      />
    </form>
  </div>
</template>

<script>
export default {
  name: "Searchform",
  components: {},
  data() {
    return {
      // searchterm: String
    };
  },
  props: {
    searchterm: {
      type: String,
      default: ""
    },
    isMenu: Boolean
  },
  methods:{
    goSearch: function(e) {
      e.preventDefault();
      if (this.searchterm) {
        this.$router.push({ path: `/search/${this.searchterm}` });
        this.$parent.menuActive = !this.$parent.menuActive;
      }
    },
    searchclick: function() {
      // this.searchterm = "";
      // this.$el.classList.remove("empty");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/css/mixins.scss";
.searchform {
  position: relative;
  background: var(--current-color);
  color: black;
  padding: 10px;
  overflow: visible;
  @include desktop {
    // height: auto;
  }
  label {
    display: none;
  }
  form {
    overflow: visible;
    height: auto;
    &.empty {
      &:before {
        display: block;
        content: "SEARCH";
        font-size: 55px;
        line-height: 60px;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: black;
        text-align: center;
        opacity: 1;
        margin-top: -1vw;
        margin-top: 3vw;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        @include desktop {
          font-size: calc(100vw / 6);
          line-height: calc(100vw / 9);
          letter-spacing: -0.035em;
          font-weight: 300;
          // margin-top: -1vw;
          // margin-top: 3vw;
          // position: absolute;
          // top: calc(50%);
          // left: 50%;
          // transform: translate(-50%, -50%);
        }
      }
    }
  }
  &.focus {
    form.empty {
      &:before {
        display: none !important;
      }
    }
  }
  input {
    position: relative;
    font-family: basel, sans-serif;
    font-size: 27px;
    // line-height: 30px;
    font-weight: 400;
    text-align: center;
    height: 100%;
    display: block;
    max-width: 100%;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 0px;
    flex-grow: 1;
    outline: none;
    // border-bottom: 3px solid black;
    margin: 0 auto 0;
    overflow: visible;
    height: 60px;
    width: 100vw;
    padding-top: 15px;
    text-transform: uppercase;
    @include desktop {
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 110px;
      line-height: 100px;
      height: calc(100vw / 6);
      padding-top: clamp(0px, 5%, 10px);
    }
  }
}
</style>
