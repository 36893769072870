var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    { showState: !_vm.showStuff },
    'articleRow',
    'articleBig',
    _vm.item.color,
    _vm.item.font
  ],on:{"mouseleave":function($event){_vm.showStuff = true}}},[_c('h2',[_vm._v(_vm._s(_vm.item.article.title))]),_c('div',{staticClass:"buttonzone"},[(_vm.item.article_link)?_c('router-link',{attrs:{"to":'/article/' + _vm.item.article.slug}},[(_vm.item.buttonText)?_c('button',{on:{"mouseover":_vm.uncover,"mouseleave":_vm.recover}},[_c('span',[_vm._v(_vm._s(_vm.item.buttonText))])]):_vm._e()]):(_vm.item.buttonText)?_c('button',{on:{"mouseover":_vm.uncover,"mouseleave":_vm.recover,"click":function($event){_vm.showStuff = false}}},[_c('span',[_vm._v(_vm._s(_vm.item.buttonText))])]):_vm._e()],1),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.item.article.subtitle)+" ")]),_c('Credits',{attrs:{"credits":_vm.item.article.credits}}),(_vm.item.show == 'cover')?_c('div',{staticClass:"background"},[(_vm.item.article.cover)?_c('GetImage',{attrs:{"image":_vm.item.article.cover,"size":"large","loading":"lazy"}}):_vm._e()],1):(_vm.item.show == 'hero')?_c('div',{staticClass:"background"},[(_vm.item.article.hero_image)?_c('GetImage',{attrs:{"image":_vm.item.article.hero_image,"size":"large","loading":"lazy"}}):_vm._e()],1):(_vm.item.show == 'video')?_c('div',{class:[{ uncover: !_vm.showStuff }, 'background']},[_c('ShowVideo',{attrs:{"embed":_vm.item.article.video}})],1):(_vm.item.show == 'slider')?_c('div',{staticClass:"background"},[_c('Slider',{attrs:{"slider":_vm.item.article.slider}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }