<template>
  <div id="playlist" v-if="playlists">
    <Playlist v-bind:item="playlists[0]" />
  </div>
</template>

<script>
import { GET_PLAYLIST } from "@/graphql/queries/getPlaylist.js";
import Playlist from "@/components/blocks/Playlist.vue";

export default {
  name: "SinglePlaylist",
  data() {
    return {
      routeParam: this.$route.params.slug
    };
  },
  props: {
    slug: String
  },
  components: {
    Playlist
  },
  apollo: {
    playlists: {
      query: GET_PLAYLIST,
      variables() {
        return {
          slug: this.routeParam
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

#playlist {
}
</style>
