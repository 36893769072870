import gql from 'graphql-tag'

export const GET_INFO = gql`
  query Info {
    info {
      id
      title
      text
    }
  }
`