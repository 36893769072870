import gql from "graphql-tag";

export const GET_CATEGORIES = gql`
  query Collections {
    collections(first: 100) {
      edges {
        node {
          id
          title
          handle
          descriptionHtml
          products(first: 250) {
            edges {
              node {
                id
                title
                handle
                descriptionHtml
                availableForSale
                variants(first:250) {
                  edges {
                    node {
                      id
                      title
                      availableForSale
                      price {
                        amount
                        currencyCode
                      }
                      sellingPlanAllocations(first:250) {
                        edges {
                          node {
                            sellingPlan {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
                images(first:20) {
                  edges {
                    node {
                      id
                      altText
                      width
                      height
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
