import gql from 'graphql-tag'

export const GET_INITIAL_HOME = gql`
  query initialHome {
    initialHome: home {
      id
      issue {
        id
        title
        season
        cover {
          id
          alternativeText
          url
          width
          height
          formats
        }
        color
      }
    }
  }
`