<template>
  <div class="menu">
    <div class="link-wrapper" v-for="item in menu" v-bind:key="item.id">
      <router-link :to="item.url" v-if="!item.highlight">
        {{ item.title }}
      </router-link>
      <router-link :to="item.url" v-else>
        <div :class="{ 'highlight' : item.highlight }">
          <marquee-text
            :repeat="3"
            :duration="2"
            :paused="false"
          >
            <span>{{ item.title }}</span>
          </marquee-text>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      menuActive: true
    };
  },
  props: {
    menu: Array
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.link-wrapper {
  display: inline-block;
  line-height: 100%;
}
.highlight {
  width: 100px;
  span {
    padding: 0 5px;
  }
}
a {
  text-transform: uppercase;
  text-decoration: none;
}
</style>
