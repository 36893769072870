import gql from 'graphql-tag'

export const GET_SUBSCRIPTION = gql`
  query Subscription {
    subscription {
      id
      title
      subscriptionProduct {
        id
        title
        description
        price
        image {
          id
          alternativeText
          width
          height
          url
          formats
        }
      }
    }
  }
`