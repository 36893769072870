import gql from 'graphql-tag'

export const GET_SELECTIONS = gql`
  query Selections {
    selections {
      id
      Name
      slug
      slider {
        id
        slides {
          id
          text
          image {
            id
            url
            width
            height
            alternativeText
            formats
          }
        }
        textslide
      }
    }
  }
`