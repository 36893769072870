import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { apolloProvider } from "./graphql/apollo.js";

// Vuex
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    cart: []
  },
  mutations: {
    updateCart(state, payload) {
      state.cart = payload;
    },
    addToCart(state, payload) {
      state.cart.push(payload);
    },
    increase(state, index) {
      state.cart[index].amount++;
    },
    decrease(state, index) {
      state.cart[index].amount -= 1;
      if (state.cart[index].amount == 0) {
        state.cart.splice(index, 1);
      }
    }
  }
});

// Transpiler for older browsers
import "core-js/stable";

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import MarqueeText from "vue-marquee-text-component";
Vue.component("marquee-text", MarqueeText);

Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  render: h => h(App),
  store: store
}).$mount("#app");
