import gql from 'graphql-tag'

export const GET_ISSUES = gql`
  query Issues {
    issues {
      id
      title
      number
      season
      cover {
        id
        alternativeText
        url
        width
        height
        formats
      }
      intro
      articles {
        id
        title
        subtitle
        slug
        created_at
        cover {
          id
          alternativeText
          url
          width
          height
          formats
        }
      }
      contributors {
        id
        name
        url
        agency
        agency_url
      }
      contribs {
        id
        name
        url
        agency
        agencyUrl
      }
      color
    }
  }
`