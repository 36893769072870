<template>
  <a
    class="article-entry"
    :key="'article-' + article.id"
    :href="'/article/' + article.slug"
  >
    <div
      v-bind:class="[{ 'cover-hide': article.cover_hide }, 'article-cover']"
    >
      <div class="imagewrap">
        <GetImage v-if="article.cover" :image="article.cover" size="medium" />
      </div>
      <div class="overlay">
        {{ article.title }}<br>
        {{ article.subtitle }}
      </div>
    </div>
    <div class="article-info">
      <div v-if="date && !startEnd" class="date">
        {{
          new Date(article.created_at).toLocaleDateString('en-US', {
            year: "numeric",
            month: "long",
            day: "numeric"
          })
        }}
      </div>
      <div v-if="startEnd">
        {{
          new Date(article.start).toLocaleDateString('en-US', {
            year: "numeric",
            month: "long",
            day: "numeric"
          })
        }} — {{
          new Date(article.end).toLocaleDateString('en-US', {
            year: "numeric",
            month: "long",
            day: "numeric"
          })
        }}
      </div>
      {{ article.title }}<br>
      {{ article.subtitle }}
      <Credits :credits="article.credits" />
    </div>
  </a>
</template>

<script>
import GetImage from "@/components/GetImage.vue";
import Credits from "@/components/Credits.vue";

export default {
  name: "ArticleListEntry",
  props: {
    article: undefined,
    date: Boolean,
    startEnd: Boolean
  },
  components: {
    GetImage,
    Credits
  }
}
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";
.article-entry {
  width: 50%;
  padding: 5px;
  margin-bottom: 5em;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  &:hover,
  &:active {
    color: var(--current-color);
    .imagewrap {
      background: var(--current-color);
    }
    img {
      filter: grayscale(100%);
      mix-blend-mode: multiply;
    }
  }
  .date {
    font-size: .7em;
    line-height: 20px;
    letter-spacing: 0.04em;
  }
  .article-cover {
    height: calc(calc(50vw - 15px) * 1.3333);
    margin-bottom: 3px;
    position: relative;
    @include desktop {
      height: calc(calc(25vw - 12.5px) * 1.3333);
      margin-bottom: 5px;
    }
    .imagewrap {
      max-height: 100%;
      overflow: hidden;
    }
    .overlay {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      max-height: 100%;
      transform: translateY(-50%);
      font-family: timesnow;
      font-size: 38px;
      line-height: 83%;
      @include desktop {
        font-size: 72px;
        line-height: 60px;
      }
      text-align: center;
      letter-spacing: -0.01em;
      // text-transform: uppercase;
      // color: #FFFFFF;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      padding: 25px;
      color: white;
      display: none;
    }
    img {
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center left;
    }
  }
  .credits {
    margin-top: 1em;
    .contributor {
      white-space: normal;
    }
    .single-credit {
      line-break: unset;
      overflow-wrap: unset;
      &:nth-of-type(n+2) {
        @include mobile {
          display: none;
        }
      } 
    }
  }
  @include desktop {
    width: 25%;
  }
}
</style>