<template>
  <div id="article" v-if="!$apollo.queries.articles.loading">
    <div v-if="articles[0].hero" class="hero">
      <GetImage :image="articles[0].cover" />
      <h1>{{ articles[0].title }}<br>
      {{ articles[0].subtitle }}</h1>
    </div>
    <div v-else-if="articles[0].hero_image" class="hero">
      <GetImage :image="articles[0].hero_image" />
      <h1>{{ articles[0].title }}<br>
      {{ articles[0].subtitle }}</h1>
    </div>
    <h1 v-else>{{ articles[0].title }}</h1>
    <ShowVideo v-if="articles[0].video" :embed="articles[0].video" />
    <div class="column">
      <Credits :credits="articles[0].credits" />
      <div class="text" v-html="articles[0].text"></div>
    </div>
    <div class="article-slider">
      <Slider
        v-if="
          articles[0].slider &&
            Object.keys(articles[0].slider.slides).length > 0
        "
        :slider="articles[0].slider"
      />
    </div>
  </div>
</template>

<script>
import { GET_ARTICLE } from "@/graphql/queries/getArticle.js";
import Slider from "@/components/Slider.vue";
import Credits from "@/components/Credits.vue";
import ShowVideo from "@/components/ShowVideo.vue";
import GetImage from "@/components/GetImage.vue";

export default {
  name: "Article",
  data() {
    return {
      routeParam: this.$route.params.slug
    };
  },
  props: {
    slug: String
  },
  components: {
    Slider,
    Credits,
    ShowVideo,
    GetImage
  },
  apollo: {
    articles: {
      query: GET_ARTICLE,
      variables() {
        return {
          slug: this.routeParam
        };
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/mixins.scss";

#article {
  padding: 10px 10px 0 10px;
  .article-slider {
    margin: 0 -10px;
  }
  .vimeo-video {
    // max-width: 1440px;
    margin: 0 auto;
    padding: 5px;
  }
  h1 {
    font-family: timesnow, serif;
    // max-width: 1340px;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 54px;
    line-height: 46px;
    letter-spacing: -0.02em;
    hyphens: auto;
    max-width: 100%;
    overflow: hidden;
    @include desktop {
      font-size: 160px;
      line-height: 125px;
      letter-spacing: -0.03em;
      margin: 0 auto 80px;
    }
  }
  .hero {
    height: 515px;
    position: relative;
    margin: -10px -10px 10px;
    img {
      position: absolute;
      top: 0px;
      left: 0px;
      min-width: 100vw;
      height: 515px;
      object-fit: cover;
      object-position: center center;
    }
    h1 {
      position: absolute;
      bottom: 0px;
      left: 0px;
      @include mobile {
        text-align: left;
        padding: 10px;
        margin: 0px;
      }
    }
    @include desktop {
      height: 800px;
      max-height: 90vh;
      img {
        height: 800px;
        max-height: 90vh;
      }
      h1 {
        width: 100%;
        max-width: 100vw;
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
  .column {
    max-width: 1440px;
    margin: 0 auto 30px;
  }
  .credits {
    margin: 0 5px 0 2px;
    font-size: 12px;
    line-height: 14px;
    @include desktop {
      margin: 0 5px;
      font-size: 16px;
      line-height: 18px;
    }
  }
  .text {
    a {
      text-decoration-color: var(--current-color);
      &:hover {
        text-decoration: none;
        color: var(--current-color);
      }
    }
    big {
      font-family: timesnow, serif;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -0.01em;
      strong {
        font-weight: 400;
      }
      font-weight: 400;
      @include desktop {
        font-size: 60px;
        line-height: 55px;
        letter-spacing: -0.02em;
      }
    }
    p {
      font-weight: 400;
      max-width: 705px;
      margin: 0 auto;
      margin-bottom: 1em;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      @include desktop {
        font-weight: 300;
        font-size: 20px;
        line-height: 26px;
      }
    }
    question {
      display: block;
      max-width: 705px;
      font-family: timesnow, serif;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.03em;
      text-align: center;
      margin: 1em 0;
      @include desktop {
        font-size: 24px;
        line-height: 26px;
      }
    }
    answer {
      font-weight: 400;
      display: block;
      max-width: 705px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      margin: 1em 0;
      @include desktop {
        font-weight: 300;
        font-size: 22px;
        line-height: 26px;
        margin: 1em 0 1em 367px;
      }
    }
  }
  figure {
    margin: 1em -10px;
    @include desktop {
      margin: 1em 0;
    }
    img,
    figcaption {
      width: 100%;
      max-width: 944px;
      margin: 0 auto;
    }
    @include mobile {
      figcaption {
        margin: 0 10px;
      }
    }
  }
  .article-slider {
    background: white;
    color: black;
  }
}
</style>