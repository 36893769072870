<template>
  <div id="issues" v-if="issues">
    <PageTitle :title="'Issues'" />
    <div
      class="issue"
      v-for="issue in issues.slice().reverse()"
      :key="'issue-' + issue.id"
      :style="'--issue-color: ' + issue.color"
    >
      <h2>
        Issue #{{ issue.number }}<br>
        “{{ issue.title }}”
      </h2>
      <div class="cover">
        <GetImage
          v-for="(cover, index) in issue.cover"
          :key="'cover-' + issue.id + '-' + index"
          :image="cover"
          size="medium"
        />
      </div>
      <div class="intro">{{ issue.intro }}</div>
      <div class="articles-heading" v-if="issue.articles.length">
        Selected articles issue #{{ issue.number }}
      </div>
      <div class="articles">
        <ArticleListEntry
          v-for="article in issue.articles"
          v-bind:article="article"
          :key="'article-' + article.id"
          :style="'--current-color: ' + issue.color"
          v-bind:date="true"
        />
      </div>
      <Contributors
        :item="{
          title: 'Contributors Issue #' + issue.number,
          contributors: issue.contribs
        }"
        v-if="issue.contribs.length"
      />
    </div>
  </div>
</template>

<script>
import { GET_ISSUES } from "@/graphql/queries/getIssues.js";
import GetImage from "@/components/GetImage.vue";
import PageTitle from "@/components/PageTitle.vue";
import Contributors from "@/components/blocks/Contributors.vue";
import ArticleListEntry from "@/components/ArticleListEntry.vue";

export default {
  name: "Issues",
  data() {
    return {}
  },
  apollo: {
    issues: {
      query: GET_ISSUES
    }
  },
  components: {
    GetImage,
    PageTitle,
    Contributors,
    ArticleListEntry
  },
  mounted: function () {
    window.setInterval(() => {
      this.$el.querySelectorAll(".cover").forEach(elem => {
        elem.append(elem.querySelector("img"));
      });
    }, 5000);
  }
}
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

#issues {
  padding: 0 10px;
  font-weight: 500;
  .issue {
    margin: 30px 0 0;
    @include desktop {
      margin: 90px 0 0;
    }
    > h2 {
      text-transform: uppercase;
      text-align: center;
      font-family: timesnow, serif;
      font-size: 34px;
      line-height: 32px;
      letter-spacing: 0em;
      margin: 0 0 25px;
      @include desktop {
        font-size: 105px;
        line-height: 80px;
        letter-spacing: -0.03em;
        margin-bottom: 42px;
      }
    }
    .cover {
      padding: 0 30px;
      margin: 0 0 30px;

      img {
        margin: 0 auto;
        width: 100%;
        max-width: 700px;
        display: block;
        &:not(:first-of-type) {
          display: none;
        }
      }
      @include desktop {
        margin: 0 0 90px;
      }
    }
    .intro {
      font-family: timesnow, serif;
      font-style: normal;
      font-weight: 300;
      font-size: 34px;
      line-height: 32px;
      letter-spacing: -0.01em;
      margin: 0 0 1em;
      @include desktop {
        font-size: 90px;
        line-height: 80px;
        letter-spacing: -0.03em;
        margin: 0 0 120px;
      }
    }
    .articles-heading {
      text-transform: uppercase;
      margin: 0 0 10px;
      @include desktop {
        margin: 0 0 15px;
      }
    }
    .articles {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
    }
    .contributors {
      background-color: var(--issue-color) !important;
      color: black;
      margin: 0 -10px;
      @include desktop {
        margin: 0 -15px;
      }
      a {
        &:hover {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>