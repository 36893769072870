<template>
  <div v-bind:class="[{ 'menu-active': menuActive }, 'burger-menu']">
    <div class="top-bar">
      <div class="menu-toggle">
        <div class="dot" @click="menuActive = !menuActive"></div>
        <img
          src="@/assets/images/x.svg"
          alt="Menu Toggle"
          @click="menuActive = !menuActive"
        />
      </div>
      <div class="logo" @click="logoClick()">
        <img src="@/assets/images/studio.svg" alt="STUDIO" />
      </div>
      <Menu id="menu-right" class="desktop" v-bind:menu="menuHeader" />
    </div>
    <div class="main-menu">
      <div class="menu-top menu-secondary" v-if="burgerTop">
        <div class="menu-item" v-for="item in burgerTop" v-bind:key="item.id">
          <router-link
            :to="item.url"
            v-on:click.native="menuActive = !menuActive"
          >
            {{ item.title }}
          </router-link>
        </div>
      </div>
      <div class="menu-categories menu-primary">
        <div class="menu-item">
          <router-link
            to="/selection"
            v-on:click.native="menuActive = !menuActive"
          >
            Selection
          </router-link>
        </div>
        <div class="menu-item" v-for="item in categories" v-bind:key="item.id">
          <router-link
            :to="'/category/' + item.slug"
            v-on:click.native="menuActive = !menuActive"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
      <Searchform />
      <div class="menu-bottom" v-if="burgerBottom">
        <div
          class="menu-item"
          v-for="item in burgerBottom"
          v-bind:key="item.id"
        >
          <router-link
            :to="item.url"
            v-on:click.native="menuActive = !menuActive"
          >
            {{ item.title }}
          </router-link>
        </div>
      </div>
      <Footer
        v-if="!$apollo.loading"
        v-bind:menu="menuFooter"
        v-bind:issue="issue"
      />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Searchform from "@/components/Searchform.vue";
import Footer from "@/components/Footer.vue";

var isMobile = false;
if (window.matchMedia("(max-width: 599px)").matches) {
  isMobile = true;
}
function scale() {
  // logo zoom
  const logo = document.querySelector("#cover-screen h1 img");
  const topBar = document.querySelector(".top-bar");
  let upperEdge = logo.getBoundingClientRect().top - 5;
  // logo file aspect ratio
  // let ratio = 4.3913258161;
  let ratio = 4.4646389765;
  // how far is the upper edge of the scaled logo scrolled towards the upper edge of final size?
  let scrollPercentage = upperEdge / (window.innerHeight - logo.offsetHeight - 5);
  // initial logo height
  let upper = (window.innerWidth - 10) / ratio;
  // final logo height
  let lower = 60;
  if (isMobile) {
    lower = 36;
  }
  // scale of logo at current scroll position
  let scale = scrollPercentage * (1 - lower / upper) + lower / upper;
  // do the deed
  logo.style.setProperty("--scale", `${scale}`);

  // h2 scroll out
  const headline = document.querySelector("#cover-screen h2");
  let headlineTop = headline.offsetHeight - headline.offsetHeight * scrollPercentage;
  headline.style.setProperty("--top", `${-headlineTop}px`);

  // visibilities
  let visibility;
  let headerVisibility;
  if (upperEdge > 0) {
    visibility = "visible";
    headerVisibility = "hidden";
  } else {
    visibility = "hidden";
    headerVisibility = "visible";
  }
  logo.style.setProperty("--visibility", `${visibility}`);
  topBar.style.setProperty("--visibility", `${headerVisibility}`);
}
function headerScroll() {
  const coverScreen = document.querySelector("#cover-screen");
  if (coverScreen) {
    var observer = new IntersectionObserver(
      entries => {
        if (entries[0]["isIntersecting"] === true) {
          scale();
        }
      },
      { threshold: [0, 0.85, 1] }
    );
    observer.observe(coverScreen);
  }
}
// if (document.querySelector("#cover-screen")) {
//   scale();
// }

export default {
  name: "BurgerMenu",
  data() {
    return {
      menuActive: false
    };
  },
  props: {
    burgerTop: Array,
    burgerBottom: Array,
    categories: Array,
    menuHeader: Array,
    menuFooter: Array,
    issue: Object
  },
  components: {
    Menu,
    Searchform,
    Footer
  },
  mounted() {
    document.addEventListener("scroll", headerScroll);
    headerScroll();
  },
  methods: {
    logoClick() {
      const r = this.$router;
      if (!document.querySelector("#home")) {
        r.push("/");
        // document
        //   .querySelector(".top-bar")
        //   .style.setProperty("--visibility", "hidden");
      }
      this.menuActive = !this.menuActive
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/css/mixins.scss";

.burger-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 115px;
  width: 115px;
  overflow: hidden;
  z-index: 990;
  --visibility: visible;
  user-select: none;
  @include desktop {
    height: 100px;
    width: 100vw;
  }
  a {
    text-transform: uppercase;
    text-decoration: none;
  }
  .top-bar {
    position: fixed;
    height: 48px;
    width: 100vw;
    display: flex;
    justify-content: center;
    visibility: var(--visibility);
    background: black;
    box-shadow: 0px 0px 4px 4px black;
    transition: box-shadow .3s linear;
    .logo {
      display: none;
      padding-top: 7px;
      cursor: pointer;
      img {
        height: 36px;
        width: auto;
      }
    }
    .menu-toggle {
      position: fixed;
      top: 7px;
      left: 7px;
      cursor: pointer;
      z-index: 1000;
      .dot {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--current-color);
        transition: box-shadow .2s linear;
        &:hover {
          box-shadow: 0 0px 5px 5px var(--current-color);
        }
      }
      img {
        display: none;
        height: 78px;
        width: 78px;
      }
    }
    @include desktop {
      height: 70px;
      .menu-toggle {
        top: 6px;
        left: 10px;
        .dot {
          width: 60px;
          height: 60px;
          border-radius: 30px;
        }
        img {
          height: 60px;
          width: 60px;
        }
      }
      .logo {
        // display: block;
        img {
          height: 60px;
          width: auto;
        }
      }
      .menu {
        .highlight {
          color: var(--current-color);
        }
      }
    }
  }
  .main-menu {
    z-index: -1;
    position: fixed;
    background-color: black;
    top: 0px;
    padding-top: 100px;
    width: 100%;
    height: 100vh;
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: calc(var(--app-height) - 115px);
    // height: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s linear;
    overflow: hidden;
    > * {
      flex-shrink: 0;
    }
    .menu-item a {
      display: block;
    }
    a:hover,
    .router-link-exact-active {
      color: var(--current-color);
    }
    .menu-primary {
      order: -1;
      font-family: timesnow, serif;
      font-weight: 300;
      font-size: 34px;
      line-height: 32px;
    }
    .menu-secondary {
      font-weight: 300;
      font-size: 36px;
      line-height: 25px;
      letter-spacing: 0.01em;
    }
    .menu-bottom {
      order: 2;
      font-weight: 300;
      font-weight: 300;
      font-size: 36px;
      line-height: 25px;
      letter-spacing: 0.01em;
    }
    @include mobile {
      // text-align: center;
      .menu-item {
        border-top: 2px solid white;
        height: 35px;
        padding: 2px 7px;
      }
      .menu-primary,
      .menu-bottom {
        border-bottom: 2px solid white;
        margin-bottom: 35px;
      }
    }
    @include desktop {
      padding-top: 73px;
      .menu-secondary {
        display: flex;
        flex-wrap: nowrap;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        height: 112px;
        align-items: center;
        font-weight: 300;
        > * {
          font-size: 30px;
          line-height: 108px;
          letter-spacing: 0.01em;
          flex-basis: 100%;
          flex-grow: 0;
          flex-shrink: 1;
          text-align: center;
        }
      }
      .menu-primary {
        order: 0;
        > * {
          // height: 118px;
          padding-top: 16px;
          padding-bottom: 10px;
          font-weight: 300;
          font-size: 105px;
          line-height: 80%;
          letter-spacing: -0.03em;
          border-bottom: 2px solid white;
          &:last-of-type {
            // border-bottom: none;
          }
        }
      }
      .menu-bottom {
        font-weight: 300;
        font-size: 110px;
        line-height: 80%;
        letter-spacing: 0.01em;
        // margin-top: 1em;
        > * {
          padding: 8px 10px 16px;
          border-bottom: 2px solid white;
          &:first-of-type {
            border-top: 2px solid white;
          }
        }
      }
    }
  }
  #menu-right {
    display: none;
    @include desktop {
      display: flex;
      position: absolute;
      right: 10px;
      top: 5px;
      font-weight: 500;
      > * {
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          margin-left: auto;
        }
        margin-left: 36px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  &.menu-active {
    // width: 100vw;
    // height: 100vh;
    // height: var(--app-height);
    background-color: black;
    overflow-y: scroll;
    .top-bar {
      box-shadow: none;
    }
    @include mobile {
      .top-bar {
        width: 100%;
      }
    }
    .menu-toggle {
      .dot {
        display: none;
      }
      img {
        display: block;
      }
      &:hover {
        background: var(--current-color);
        img {
          background: black;
          filter: grayscale(100%);
          mix-blend-mode: multiply;
          // display: none;
          // &.hover {
          //   display: block;
          // }
        }
      }
    }
    .logo {
      display: block;
      @include mobile {
        width: 100vw;
        padding: 5px;
        bottom: 0;
        position: fixed;
        img {
          height: auto;
        }
      }
    }
    .main-menu {
      pointer-events: all;
      opacity: 1;
      overflow: auto;
    }
  }
  #footer {
    order: 2;
    @include mobile {
      display: none;
    }
    .scrolltop {
      display: none;
    }
  }
  #issue-cover {
    display: none;
  }
}
</style>
