<template>
  <div id="info" v-if="!$apollo.queries.info.loading">
    <PageTitle :title="info.title" class="title" />
    <div v-html="info.text" class="text" />
  </div>
</template>

<script>
import { GET_INFO } from "@/graphql/queries/getInfo.js";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "Info",
  data() {
    return {
      routeParam: this.$route.params.slug
    };
  },
  apollo: {
    info: {
      query: GET_INFO
    }
  },
  components: {
    PageTitle
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

#info {
  padding: 0 10px 10px 10px;
  .text {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    @include desktop {
      font-size: 20px;
      letter-spacing: 0.01em;
      max-width: 705px;
    }
  }
}
</style>