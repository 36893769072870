// apollo.js

import Vue from "vue";
import { ApolloClient } from "apollo-client";
// import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import possibleTypes from "./possibleTypes.json";

// const shopifyToken = process.env.VUE_SHOPIFY_TOKEN || null
const shopifyToken = "2d6568a7937a64184f897a2f4299efc8";

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT
});
const httpShopifyLink = new HttpLink({
  // uri: process.env.VUE_SHOPIFY_ENDPOINT,
  uri: "https://thestudiomagazine.myshopify.com/api/2022-04/graphql.json",
  headers: {
    "X-Shopify-Storefront-Access-Token": shopifyToken,
    "Content-Type": "application/json",
    "Accept": "application/graphql"
  }
});

// const authShopifyLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = process.env.VUE_SHOPIFY_TOKEN;
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : ""
//     }
//   };
// });

// const authShopifyLink = setContext(async (_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = process.env.VUE_SHOPIFY_TOKEN;
//   // Return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token || ""
//     }
//   };
// });

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    fragmentMatcher
    // possibleTypes: {
    //   HomeBlocksDynamicZone: [
    //     "ComponentBlocksArticleBig",
    //     "ComponentBlocksArticleImages",
    //     "ComponentBlocksContributors",
    //     "ComponentBlocksNewsletter",
    //     "ComponentBlocksPlaylist"
    //   ]
    // }
  }),
  connectToDevTools: true
});
// Client for Shopify
const apolloShopifyClient = new ApolloClient({
  link: httpShopifyLink,
  cache: new InMemoryCache(),
  connectToDevTools: true
});

// Install the Vue plugin
Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  clients: {
    apolloClient,
    apolloShopifyClient
  },
  defaultClient: apolloClient
});
