<template>
  <div
    id="app"
    v-if="!$apollo.loading && initialHome"
    :class="this.$route.name.toLowerCase()"
    :style="'--current-color: ' + initialHome.issue.color"
  >
    <!-- <Header
      v-if="
        !$apollo.queries.menu.loading && !$apollo.queries.initialHome.loading
      "
      v-bind:menuHeader="menu.header"
      v-bind:menuMain="menu.footer"
    /> -->
    <BurgerMenu
      v-if="!$apollo.loading"
      v-bind:burgerTop="menu.burger_top"
      v-bind:burgerBottom="menu.burger_bottom"
      v-bind:categories="categories"
      v-bind:menuHeader="menu.header"
      v-bind:menuFooter="menu.footer"
      v-bind:issue="initialHome.issue"
    />
    <router-view :key="$route.fullPath" />
    <Footer
      v-if="!$apollo.loading"
      v-bind:menu="menu.footer"
      v-bind:issue="initialHome.issue"
    />
  </div>
</template>

<script>
import { GET_MENU } from "@/graphql/queries/getMenu.js";
import { GET_INITIAL_HOME } from "@/graphql/queries/getInitialHome.js";
import { GET_CATEGORIES } from "@/graphql/queries/getCategories.js";

import { getFile } from "@/components/http.js";

// import Header from "@/components/Header.vue";
import BurgerMenu from "@/components/BurgerMenu.vue";
import Footer from "@/components/Footer.vue";

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
}
window.addEventListener("resize", appHeight);
appHeight();

export default {
  name: "App",
  data() {
    return {
      state: {
        cart: []
      }
    }
  },
  apollo: {
    menu: {
      query: GET_MENU
    },
    categories: {
      query: GET_CATEGORIES
    },
    initialHome: {
      query: GET_INITIAL_HOME
    }
  },
  components: {
    // Header,
    Footer,
    BurgerMenu
  },
  methods: {
    getFile
  }
};
</script>
<style lang="scss">
@import "./assets/css/fonts.scss";
@import "./assets/css/mixins.scss";
html {
  overflow-x: hidden;
  max-width: 100vw;
}
body {
  color: #fff;
  background: #000;
  max-width: 100vw;
  // overflow-x: hidden;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::selection {
  background: var(--current-color);
  color: black;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
p {
  // hyphens: auto;
}
#app {
  --current-color: white;
  width: 100%;
  max-width: 100vw;
  // overflow-x: hidden;
  font-family: basel, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.03em;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  padding-top: 30px;
  padding-top: 60px;
  @include desktop {
    padding-top: 75px;
  }
}
a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: var(--current-color);
  &:hover {
    text-decoration: none;
    color: var(--current-color);
  }
}
img {
  display: block;
  max-width: 100%;
}
button {
  font-family: basel, sans-serif;
  white-space: nowrap;
  margin: 0 auto;
  display: block;
  height: 40px;
  width: auto;
  padding: 0 30px;
  font-size: 23px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  background: white;
  cursor: pointer;
  span {
    position: relative;
    top: -1px;
  }
  &:hover {
    background: var(--current-color) !important;
    color: black;
  }
  @include desktop {
    font-size: 50px;
    padding: 0 60px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    span {
      top: -4px;
    }
  }
}
@include desktop {
  .mobile {
    display: none;
  }
}
@include mobile {
  .desktop {
    display: none;
  }
}
</style>
