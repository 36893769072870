<template>
  <div class="heading-wrapper">
    <div class="hoverscroll-wrapper">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";
.heading-wrapper {
  max-width: 100vw;
  overflow: hidden;
}
.hoverscroll-wrapper {
  @include desktop {
    // width: max-content;
    // min-width: 100vw;
    // position: relative;
    // > * {
    //   width: max-content;
    //   position: relative;
    //   left: 0px;
    //   transition: left 3s linear;
    // }
    // &:hover > * {
    //   left: calc(100vw - 100%);
    // }
  }
}
h1 {
  font-weight: 300;
  font-family: basel, sans-serif;
  text-transform: uppercase;
  font-size: 18.2vw;
  line-height: 83%;
  letter-spacing: -0.05em;
  position: relative;
  top: -7px;
  @include desktop {
    top: -0.1em;
    // font-size: 18.6vw;
    line-height: 80%;
    letter-spacing: -0.04em;
    margin-bottom: -7px;
  }
}
</style>