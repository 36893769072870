<template>
  <div class="credits">
    <div
      class="single-credit"
      v-for="(credit, index) in credits"
      :key="'credit-' + index"
    >
      <span class="contribution">{{ credit.contribution }} </span>
      <span
        class="contributor"
        v-for="(contributor, index) in credit.contributors"
        :key="'contributor-' + index"
      >
        <a :href="contributor.url" class="person" target="_blank">{{
          contributor.name
        }}</a
        ><a
          v-if="contributor.agency"
          class="agency"
          :href="contributor.agencyUrl"
          target="_blank"
          >@{{ contributor.agency }}</a>
        <span v-if="credit.contributors.length > index + 1">, </span><wbr>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Credits",
  props: {
    credits: undefined
  }
}
</script>

<style lang="scss" scoped>
.credits {
  font-weight: 400;
  a {
    text-decoration: none;
    text-transform: uppercase;
  }
  .single-credit {
    line-break: anywhere;
    overflow-wrap: break-word;
  }
  .contributor {
    white-space: nowrap;
  }
  .person {
  }
  .agency {
    font-size: .7em;
    line-height: 1em;
  }
}
</style>