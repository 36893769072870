<template>
  <div id="archive" v-if="archive">
    <PageTitle :title="'Archive'" />
    <div class="articles">
      <ArticleListEntry
        v-for="article in archive.slice().reverse()"
        v-bind:article="article"
        :key="'article-' + article.id"
        v-bind:date="true"
      />
    </div>
  </div>
</template>

<script>
import { GET_ARCHIVE } from "@/graphql/queries/getArchive.js";
import PageTitle from "@/components/PageTitle.vue";
import ArticleListEntry from "@/components/ArticleListEntry.vue";

export default {
  name: "Archive",
  data() {
    return {};
  },
  apollo: {
    archive: {
      query: GET_ARCHIVE
    }
  },
  components: {
    PageTitle,
    ArticleListEntry
  }
}
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

#archive {
  padding: 0 10px;
  font-weight: 500;
  .articles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
}
</style>