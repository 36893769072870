<template>
  <div id="home">
    <CoverScreen />
    <div class="content">
      <div class="blocks" v-if="!$apollo.queries.home.loading">
        <marquee-text :duration="50" class="issue-title">
          <h3>{{ home.issue.title }}</h3>
        </marquee-text>
        <!-- <DiorRunway /> -->
        <!-- <EmbedVideo /> -->
        <ArticleBlock
          v-for="(item, index) in home.blocks.slice().reverse()"
          :key="'block-' + index"
          v-bind:item="item"
          v-bind:even="index % 2 == 0 ? true : false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { GET_HOME } from "@/graphql/queries/getHome.js";

import ArticleBlock from "@/components/ArticleBlock.vue";
import CoverScreen from "@/components/CoverScreen.vue";
// import EmbedVideo from "@/components/EmbedVideo.vue";
// import DiorRunway from "@/components/DiorRunway.vue";

// if (document.querySelector("#cover-screen")) {
//   headerScroll();
// }

export default {
  name: "Home",
  components: {
    ArticleBlock,
    CoverScreen
    // EmbedVideo,
    // DiorRunway
  },
  data() {
    return {}
  },
  apollo: {
    home: {
      query: GET_HOME
    }
  },
  mounted() {
    document
      .querySelector(".top-bar")
      .style.setProperty("--visibility", "hidden");
  },
};
</script>

<style lang="scss">
@import "../assets/css/mixins.scss";
.burger-menu .top-bar {
  // --visibility: hidden;
}
.home {
  .burger-menu {
    .top-bar {
      .logo {
        display: block;
      }
    }
  }
}
#home {
  margin-top: var(--app-height);
  max-width: 100vw;
  overflow-x: hidden;
  .issue-title {
    height: 105px;
    h3 {
      display: inline-block;
      font-family: timesnow;
      font-size: 135px;
      line-height: 100px;
      letter-spacing: -0.07em;
      text-transform: uppercase;
      padding: 0 100px 0 0

    }
    @include desktop {
      height: 338px;
      h3 {
        font-size: 480px;
        line-height: 338px;
        letter-spacing: -0.11em;
      }
    }
  }
  .content {
    background: black;
    min-height: 100vh;
  }
}
</style>
