<template>
  <div id="playlists" v-if="playlists">
    <a
      v-for="playlist in playlists.slice().reverse()"
      :key="'playlist-' + playlist.id"
      class="playlist"
      :href="'/playlist/' + playlist.slug"
    >
      <div class="cover">
        <div class="disc">
          <div class="imagewrap">
            <GetImage :image="playlist.cover" loading="lazy" size="medium" />
          </div>
          <div class="date">
            {{
              new Date(playlist.created_at).toLocaleDateString('en-US', {
                year: "numeric",
                month: "long",
                day: "numeric"
              })
            }}
          </div>
          {{ playlist.title }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { GET_PLAYLISTS } from "@/graphql/queries/getPlaylists.js";
import GetImage from "@/components/GetImage.vue";

export default {
  name: "CategoryPlaylists",
  data() {
    return {};
  },
  apollo: {
    playlists: {
      query: GET_PLAYLISTS
    }
  },
  components: {
    GetImage
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/css/mixins.scss";

#playlists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  color: white;
  .playlist {
    width: 100%;
    padding: 5px;
    text-decoration: none;
    overflow: hidden;
    margin-bottom: 60px;
    .imagewrap {
      border-radius: 50%;
      overflow: hidden;
      margin: 0 30px 30px 30px;
    }
    &:hover,
    &:active {
      color: var(--current-color);
      .imagewrap {
        background: var(--current-color);
      }
      img {
        filter: grayscale(100%);
        mix-blend-mode: multiply;
      }
    }
    .date {
      font-size: .7em;
      line-height: 20px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    .cover {
      position: relative;
      background: black;
      h3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(30deg);
        font-size: 37px;
        line-height: 34px;
        letter-spacing: -0.01em;
        text-align: center;
        word-spacing: 100vw;
        padding-bottom: 0.15em;
        z-index: 99;
        transform-origin: center center;
        span {
          margin: 0.5em 0;
          display: block;
          width: 100%;
          font-size: 2em;
        }
        @include desktop {
          font-size: 60px;
          line-height: 51px;
          letter-spacing: -0.03em;
        }
      }
      img {
        height: auto;
        width: 100%;
        // border-radius: 50%;
      }
    }
    @include desktop {
      width: 50%;
    }
  }
}
</style>
