import gql from 'graphql-tag'

export const GET_HOME = gql`
  query Home {
    home {
      id
      issue {
        id
        title
        season
        cover {
          id
          alternativeText
          url
          width
          height
          formats
        }
      }
      blocks {
        __typename
        ... on ComponentBlocksArticleImages {
          id
          title_text_1
          title_text_2
          title_text_3
          article_link
          external_link_url
          image_options
          speed
          height
          article {
            id
            title
            slug
            cover {
              url
              width
              height
              alternativeText
              formats
            }
            hero_image {
              url
              width
              height
              alternativeText
              formats
            }
            slider {
              slides {
                id
                image {
                  url
                  width
                  height
                  alternativeText
                  formats
                }
              }
            }
          }
        }
        ... on ComponentBlocksArticleImagesStatic {
          id
          title_text_1
          title_text_2
          title_text_3
          article_link
          external_link_url
          height
          images {
            id
            url
            width
            height
            alternativeText
            formats
          }
          article {
            id
            title
            slug
          }
        }
        ... on ComponentBlocksArticleBig {
          id
          font
          buttonText
          color
          show
          article_link
          article {
            title
            subtitle
            video
            cover {
              url
              width
              height
              alternativeText
              formats
            }
            hero_image {
              url
              width
              height
              alternativeText
              formats
            }
            credits {
              contribution
              contributors {
                name
                url
                agency
                agencyUrl
              }
            }
            slug
            slider {
              slides {
                image {
                  url
                  width
                  height
                  alternativeText
                  caption
                  formats
                }
              }
            }
          }
        }
        ... on ComponentBlocksNewsletter {
          id
          placeholder
          button_text
        }
        ... on ComponentBlocksContributors {
          id
          title
          contributor {
            name
            url
            agency
            agency_url
          }
        }
        ... on ComponentBlocksPlaylist {
          id
          playlist {
            id
            title
            title_1
            title_2
            title_3
            description
            cover {
              url
              width
              height
              alternativeText
              formats
            }
            track {
              id
              title
              artist
              label
              url
            }
          }
        }
        ... on ComponentBlocksYoutubeEmbed {
          id
          yt_id
          cover {
            url
            width
            height
            alternativeText
            formats
          }
        }
      }
    }
  }
`