<template>
  <div class="articleRow" :style="containerStyle">
    <marquee-text
      v-if="item.image_options == 'cover' || item.image_options == 'hero'"
      :repeat="12"
      :duration="item.speed"
      :reverse="even"
      :paused="pause"
    >
      <div class="item-once">
        <div class="item-slide">
          <div class="item-title title-custom" v-if="owntitle">
            <div>
              {{ item.title_text_1 }}
            </div>
            <div>
              {{ item.title_text_2 }}
            </div>
            <div>
              {{ item.title_text_3 }}
            </div>
          </div>
          <div class="item-title title-from-article" v-if="!owntitle">
            {{ item.article.title }}
          </div>
          <GetImage
            v-if="item.image_options == 'cover'"
            :image="item.article.cover"
            loading="lazy"
            size="large"
          />
          <GetImage
            v-else-if="item.image_options == 'hero'"
            :image="item.article.hero_image"
            loading="lazy"
            size="large"
          />
        </div>
      </div>
    </marquee-text>
    <marquee-text
      v-if="item.image_options == 'slides'"
      :repeat="3"
      :duration="item.speed"
      :paused="pause"
    >
      <div class="item-once">
        <div
          class="item-slide"
          v-for="slide in item.article.slider.slides"
          :key="'slide-' + slide.id"
        >
          <div class="item-title title-custom" v-if="owntitle">
            <div>
              {{ item.title_text_1 }}
            </div>
            <div>
              {{ item.title_text_2 }}
            </div>
            <div>
              {{ item.title_text_3 }}
            </div>
          </div>
          <div class="item-title title-from-article" v-if="!owntitle">
            {{ item.article.title }}
          </div>
          <GetImage :image="slide.image" loading="lazy" size="large" />
        </div>
      </div>
    </marquee-text>
    <router-link v-if="item.article_link" :to="'/article/' + item.article.slug">
      <div class="articleLink"></div>
    </router-link>
    <a
      v-else-if="item.external_link_url"
      :href="item.external_link_url"
      target="_blank"
    >
      <div class="articleLink"></div>
    </a>
  </div>
</template>

<script>
import GetImage from "@/components/GetImage.vue";

export default {
  name: "ArticleImages",
  data () {
    return {
      pause: true
    }
  },
  props: {
    item: undefined,
    even: undefined
  },
  computed: {
    owntitle: function() {
      if (this.item && this.item.title_text_1) return true;
      else return false;
    },
    containerStyle() {
      return {
        "--section-height": this.item.height + "px"
      };
    }
  },
  components: {
    GetImage
  },
  mounted() {
    const mq = this;
    var observer = new IntersectionObserver(function(entries) {
      if (entries[0]["isIntersecting"] === true) {
        mq.pause = false;
      } else {
        mq.pause = true;
      }
    });
    observer.observe(this.$el);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.articleRow {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(0.65 * var(--section-height));
  @include desktop {
    height: var(--section-height);
  }
  .item-once {
    white-space: nowrap;
    width: max-content;
    display: inline-block;
    background: black;
    height: calc(0.65 * var(--section-height));
    @include desktop {
      height: var(--section-height);
    }
    .item-slide {
      padding-left: 10px;
      display: inline-block;
      position: relative;
    }
    img {
      height: calc(0.65 * var(--section-height));
      width: auto;
      @include desktop {
        height: var(--section-height);
      }
    }
  }
  .item-title {
    font-weight: 500;
    overflow: hidden;
    background: black;
    width: calc(0.65 * var(--section-height));
    text-transform: uppercase;
    position: absolute;
    bottom: 0px;
    left: 10px;
    font-size: 12px;
    letter-spacing: .01em;
    height: 10px;
    line-height: 9px;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    // transition: opacity .2s ease-in-out;
    @include desktop {
      width: var(--section-height);
      opacity: 0;
    }
    @include mobile {
      div:nth-of-type(2) {
        display: none;
      }
    }
  }
  &:hover {
    .item-title {
      opacity: 1;
    }
  }
  .title-from-article {
    line-height: 12px;
  }
  .title-custom {
    display: flex;
    justify-content: space-between;
  }
  .articleLink {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
</style>
