<template>
  <div id="category" :class="'category-' + this.routeParam" v-if="category">
    <PageTitle :title="category[0].name" />
    <div
      class="articles newest"
      v-if="
        this.routeParam != 'exhibitions' &&
          category[0].articles
            .slice()
            .reverse()
            .filter(elem => lessThanFiveDaysAgo(elem.created_at))[0]">
      <ArticleListEntry
        v-for="article in category[0].articles.slice().reverse().filter(elem => lessThanFiveDaysAgo(elem.created_at))"
        v-bind:article="article"
        :key="'article-' + article.id"
        v-bind:date="true"
      />
    </div>
    <div
      class="articles"
      v-if="
        this.routeParam != 'exhibitions' &&
          category[0].articles
            .slice()
            .reverse()
            .filter(elem => !lessThanFiveDaysAgo(elem.created_at))[0]">
      <ArticleListEntry
        v-for="article in category[0].articles.slice().reverse().filter(elem => !lessThanFiveDaysAgo(elem.created_at))"
        v-bind:article="article"
        :key="'article-' + article.id"
        v-bind:date="true"
      />
    </div>
    <div v-else>
      <SubTitle
        :title="'Upcoming'"
        v-if="
          category[0].articles
            .slice()
            .reverse()
            .filter(elem => isUpcoming(elem.start))[0]
        " />
      <div class="articles">
        <ArticleListEntry
          v-for="article in category[0].articles.slice().reverse().filter(elem => isUpcoming(elem.start))"
          v-bind:article="article"
          :key="'article-' + article.id"
          v-bind:startEnd="true"
          class="upcoming"
        />
      </div>
      <SubTitle
        :title="'Current'"
        v-if="
          category[0].articles
            .slice()
            .reverse()
            .filter(elem => isHappening(elem.start, elem.end))[0]" />
      <div class="articles">
        <ArticleListEntry
          v-for="article in category[0].articles.slice().reverse().filter(elem => isHappening(elem.start, elem.end))"
          v-bind:article="article"
          :key="'article-' + article.id"
          v-bind:startEnd="true"
          class="current"
        />
      </div>
      <SubTitle
        :title="'Past'"
        v-if="
          category[0].articles
            .slice()
            .reverse()
            .filter(elem => isOver(elem.end))[0]
        " />
      <div class="articles">
        <ArticleListEntry
          v-for="article in category[0].articles.slice().reverse().filter(elem => isOver(elem.end))"
          v-bind:article="article"
          :key="'article-' + article.id"
          v-bind:startEnd="true"
          class="past"
        />
      </div>
    </div>
    <SubTitle :title="'Playlists'" v-if="this.routeParam == 'music-culture'" />
    <CategoryPlaylists v-if="this.routeParam == 'music-culture'" />
  </div>
</template>

<script>
import { GET_CATEGORY } from "@/graphql/queries/getCategory.js";
import PageTitle from "@/components/PageTitle.vue";
import ArticleListEntry from "@/components/ArticleListEntry.vue";
import CategoryPlaylists from "@/components/CategoryPlaylists.vue";
import SubTitle from "@/components/SubTitle.vue";

export default {
  name: "Category",
  data() {
    return {
      routeParam: this.$route.params.slug
    };
  },
  props: {
    slug: String
  },
  apollo: {
    category: {
      query: GET_CATEGORY,
      variables() {
        return {
          slug: this.routeParam
        };
      }
    }
  },
  components: {
    PageTitle,
    ArticleListEntry,
    CategoryPlaylists,
    SubTitle
  },
  methods: {
    isUpcoming(date) {
      const now = new Date().getTime();
      return new Date(now) < new Date(date);
    },
    isHappening(start, end) {
      const now = new Date().getTime();
      return new Date(end) < new Date(now) && Date(start) > new Date(now);
    },
    isOver(date) {
      const now = new Date().getTime();
      if (date) {
        return new Date(now) > new Date(date);
      } else return false;
    },
    lessThanFiveDaysAgo(date) {
      const timestamp = new Date().getTime() - (5 * 24 * 60 * 60 * 1000);
      return new Date(timestamp) < new Date(date);
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

#category {
  padding: 0 10px;
  font-weight: 500;
  .articles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
  .newest .article-entry,
  .upcoming .article-entry {
    width: 100%;
    .overlay {
      display: block;
    }
    @include desktop {
      width: 50%;
      .cover-hide {
        .imagewrap {
          height: 100%;
          width: 100%;
          img {
            display: none;
          }
        }
      }
    }
  }
}
</style>
