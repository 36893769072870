import gql from 'graphql-tag'

export const GET_SEARCH = gql`
  query Search($searchterm: String!) {
    search: articles(
      where: {
        _or: [
          { text_contains: $searchterm },
          { title_contains: $searchterm },
          { subtitle_contains: $searchterm }
        ]
      }
    ) {
      id
      created_at
      title
      subtitle
      slug
      credits {
        id
        contribution
        contributors {
          id
          name
          url
          agency
          agencyUrl
        }
      }
      cover {
        id
        alternativeText
        url
        width
        height
        formats
      }
    }
  }
`