import gql from 'graphql-tag'

export const GET_ARTICLE = gql`
  query Article($slug: String!) {
    articles (where: { slug: $slug }) {
      id
      slug
      title
      subtitle
      text
      video
      hero
      credits {
        id
        contribution
        contributors {
          id
          name
          url
          agency
          agencyUrl
        }
      }
      slider {
        id
        slides {
          id
          text
          image {
            id
            url
            width
            height
            alternativeText
            formats
          }
        }
        textslide
      }
      cover {
        id
        url
        width
        height
        alternativeText
        formats
      }
      hero_image {
        id
        url
        width
        height
        alternativeText
        formats
      }
    }
  }
`