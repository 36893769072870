<template>
  <div id="youtubeEmbed">
    <div
      class="videoWrapper"
      style="position:relative;padding-bottom:56.25%;height:0;overflow:hidden;max-width:100%;background:transparent;"
      v-if="item.yt_id"
    >
      <iframe width="2240" height="1260" :src="src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <div class="cover">
        <GetImage :image="item.cover" loading="lazy" size="large" />
      </div>
      <button
        @click="playVideo">
        <span>Play</span>
      </button>
    </div>
  </div>
</template>

<script>
import GetImage from "@/components/GetImage.vue";

export default {
  name: "YoutubeEmbed",
  data() {
    return {}
  },
  props: {
    item: undefined
  },
  components: {
    GetImage
  },
  computed: {
    src: function() {
      if (this.item.yt_id) {
        return "https://www.youtube.com/embed/" + this.item.yt_id + "";
      } else return "";
    }
  },
  methods: {
    playVideo() {
      this.$el.querySelector("iframe").src += '?autoplay=1';
      this.$el.querySelector(".cover").classList.add('hide');
      this.$el.querySelector("button").classList.add('hide');
    }
  },
};
</script>

<style scoped lang="scss">
#youtubeEmbed {
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: transparent;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 10;
    transition: opacity .5s linear;
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    transform: translate(-50%, -50%);
    transition: opacity .5s linear;
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
