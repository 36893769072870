import gql from "graphql-tag";

export const GET_ARCHIVE = gql`
  query Archive {
    archive: articles {
      id
      created_at
      title
      subtitle
      slug
      credits {
        id
        contribution
        contributors {
          id
          name
          url
          agency
          agencyUrl
        }
      }
      cover {
        id
        alternativeText
        url
        width
        height
        formats
      }
    }
  }
`;
