<template>
  <SingleSelection :selection="selection[0]" v-if="selection" />
</template>

<script>
import { GET_SELECTION } from "@/graphql/queries/getSelection.js";
import SingleSelection from "@/components/SingleSelection.vue";

export default {
  name: "Selection",
  data() {
    return {
      routeParam: this.$route.params.slug
    };
  },
  props: {
    slug: String
  },
  components: {
    SingleSelection
  },
  apollo: {
    selection: {
      query: GET_SELECTION,
      variables() {
        return {
          slug: this.routeParam
        };
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/mixins.scss";

#single-selection {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-scrollbar: none;
  position: relative;
  .prevnext {
    display: none;
    @include desktop {
      display: block;
      position: fixed;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      > * {
        width: 50%;
        height: 100%;
      }
      .prev {
        cursor: url("../assets/images/arrow-l.svg") 47 28, w-resize;
      }
      .next {
        cursor: url("../assets/images/arrow-r.svg") 47 28, e-resize;
      }
    }
  }
  .counter {
    position: fixed;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: "tnum" on, "lnum" on;
    @include desktop {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .selection-slide {
    position: relative;
    width: 100vw;
    min-width: 100vw;
    height: calc(100vh - 60px);
    height: calc(var(--app-height) - 60px);
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    scroll-snap-align: start;
    padding: 10px 10px 37px;
    @include desktop {
      padding: 15px 15px 53px;
    }
    .slide-image {
      height: 100%;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .caption {
      position: absolute;
      bottom: 10px;
      left: 80px;
      font-size: 14px;
      line-height: 17px;
      @include desktop {
        font-size: 18px;
        line-height: 20px;
        left: 240px;
      }
    }
    &.slide-first {
      // padding: 10px 10px 37px;
    }
    @include desktop {
      height: calc(100vh - 75px);
      height: calc(var(--app-height) - 75px);
    }
    .textslide {
      margin: auto 0;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      @include desktop {
        font-size: 30px;
        line-height: 34px;
        margin: auto auto -46px;
        max-width: 1300px;
        padding: 0 50px;
      }
    }
  }
}
#app.selection {
  #footer {
    display: none;
  }
}
</style>
