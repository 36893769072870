<template>
  <div id="stockists">
    <PageTitle :title="'Stockists'" />
    <div class="stockists-list" v-if="stockists">
      <div
        class="stockists-country"
        v-for="country in stockists.countries"
        :key="country.id"
      >
        <h3>{{ country.name }}</h3>
        <div
          class="stockists-stockist"
          v-for="entry in country.stockists"
          :key="entry.id"
        >
          <div class="name">
            {{ entry.name }}
          </div>
          <div class="address">
            {{ entry.address }}
          </div>
          <div class="city">
            {{ entry.city }}
          </div>
          <div class="link-map">
            <a :href="entry.map_url" target="_blank">
              <img src="@/assets/images/link-map.svg" alt="Location" />
              <img
                src="@/assets/images/link-map-green.svg"
                alt="Location"
                class="hover"
              />
            </a>
          </div>
          <div class="link-web">
            <a :href="entry.web_url" target="_blank">
              <img src="@/assets/images/link-web.svg" alt="Web" />
              <img
                src="@/assets/images/link-web-green.svg"
                alt="Web"
                class="hover"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_STOCKISTS } from "@/graphql/queries/getStockists.js";

import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "Stockists",
  data() {
    return {}
  },
  apollo: {
    stockists: {
      query: GET_STOCKISTS
    }
  },
  components: {
    PageTitle
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

#stockists {
  margin: 0 10px;
  .stockists-list {
    margin-top: 12px;
    @include desktop {
      margin-top: 20px;
    }
    .stockists-country {
      h3 {
        font-size: 30px;
        line-height: 37px;
        height: 50px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: black;
        background: var(--current-color);
        margin: 0 -10px;
        padding: 5px 10px;
        position: sticky;
        top: 50px;
        z-index: 10;
        @include desktop {
          top: 72px;
          font-size: 45px;
          line-height: 45px;
          height: 60px;
        }
      }
      .stockists-stockist {
        position: relative;
        margin: 0 -10px;
        border-bottom: 2px solid white;
        padding: 6px 10px 7px;
        &:last-of-type {
          border: none;
        }
        font-size: 16px;
        line-height: 17.6px;
        .name {
          text-transform: uppercase;
          @include mobile {
            height: 53px;
          }
        }
        .name,
        .address,
        .city {
          max-width: calc(100vw - 140px);
        }
        .link-web a,
        .link-map a {
          text-decoration: none;
          display: block;
        }
        .link-web {
          position: absolute;
          right: 70px;
          top: 6px;
        }
        .link-map {
          position: absolute;
          right: 20px;
          top: 6px;
        }
        a img.hover {
          display: none;
        }
        a:active img {
          display: none;
          &.hover {
            display: block;
          }
        }
        @include desktop {
          font-size: 20px;
          line-height: 22px;
          display: flex;
          padding: 20px 36px 20px 10px;
          text-transform: uppercase;
          .city {
            width: 18%;
            min-width: 170px;
            padding-right: 20px;
            order: 1;
          }
          .name {
            width: 30%;
            min-width: 230px;
            padding-right: 20px;
            order: 2;
          }
          .address {
            width: 52%;
            padding-right: 100px;
            order: 3;
          }
          .link-web {
            right: 110px;
            top: 8px;
          }
          .link-map {
            position: absolute;
            right: 36px;
            top: 8px;
          }
          a {
            height: 44px;
            width: 44px;
            width: auto;
            img {
              height: 44px;
              width: auto;
            }
            &:hover {
              background: var(--current-color);
              img {
                background: black;
                filter: grayscale(100%);
                mix-blend-mode: multiply;
                // display: none;
                // &.hover {
                //   display: block;
                // }
              }
            }
          }
        }
      }
    }
  }
}
</style>