<template>
  <div id="single-selection">
    <div class="selection-slide slide-first slide-active">
      <SubTitle :title="selection.Name" />
      <div
        class="textslide"
        v-if="selection.slider.textslide"
        v-html="selection.slider.textslide"
      ></div>
    </div>
    <div
      class="selection-slide"
      v-for="slide in selection.slider.slides"
      :key="'slide-' + slide.id"
    >
      <div class="slide-image">
        <GetImage :image="slide.image" loading="lazy" />
      </div>
      <div class="caption" v-html="slide.text"></div>
    </div>
    <div class="counter">
      {{ currentNo }} / {{ selection.slider.slides.length + 1 }}
    </div>
    <div class="prevnext">
      <div class="prev" v-on:click="scroll()"></div>
      <div class="next" v-on:click="scroll(true)"></div>
    </div>
  </div>
</template>

<script>
import GetImage from "@/components/GetImage.vue";
import SubTitle from "@/components/SubTitle.vue";

export default {
  name: "Selection",
  data() {
    return {
      currentNo: 1
    };
  },
  props: {
    selection: undefined
  },
  components: {
    GetImage,
    SubTitle
  },
  mounted() {
    const selection = this;
    const items = Array.from(document.querySelectorAll(".selection-slide"));
    // create an observer with the list as intersection root
    const observer = new IntersectionObserver(onIntersectionObserved, {
      root: selection.$el,
      threshold: 0.7
    });
    // observe each item
    items.forEach(item => {
      observer.observe(item);
    });
    // when the observer detects an entry changing
    // (item entering or exiting  list)
    // and the entry is intersecting
    // get the intersecting item’s index
    // set the number
    function onIntersectionObserved(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          document
            .querySelector(".slide-active")
            .classList.remove("slide-active");
          entry.target.classList.add("slide-active");
          const intersectingIndex = items.indexOf(entry.target);
          selection.currentNo = intersectingIndex + 1;
        }
      });
    }
  },
  methods: {
    scroll(next) {
      var elem;
      if (next) {
        elem = document.querySelector(".slide-active").nextElementSibling;
      } else {
        elem = document.querySelector(".slide-active").previousElementSibling;
      }
      elem.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";
</style>