import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Subscription from "../views/Subscription.vue";
import SubscriptionSuccessful from "../views/SubscriptionSuccessful.vue";
import Article from "../views/Article.vue";
import Info from "../views/Info.vue";
import Shop from "../views/Shop.vue";
import ShopModal from "../components/ShopModal.vue";
import Stockists from "../views/Stockists.vue";
import Issues from "../views/Issues.vue";
import Archive from "../views/Archive.vue";
import Category from "../views/Category.vue";
import SinglePlaylist from "../views/SinglePlaylist.vue";
import Selections from "../views/Selections.vue";
import Selection from "../views/Selection.vue";
import Search from "../views/Search.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/info",
    name: "Info",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Info
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription
  },
  {
    path: "/subscription/successful",
    name: "Subscription-successful",
    component: SubscriptionSuccessful
  },
  {
    path: "/article/:slug",
    name: "Article",
    component: Article
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
    children: [
      {
        path: ":slug",
        name: "Shop Item",
        component: ShopModal,
        props: true
      }
    ]
  },
  {
    path: "/stockists",
    name: "Stockists",
    component: Stockists
  },
  {
    path: "/issues",
    name: "Issues",
    component: Issues
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive
  },
  {
    path: "/category/:slug",
    name: "Category Archive",
    component: Category
  },
  {
    path: "/playlist/:slug",
    name: "Playlist",
    component: SinglePlaylist
  },
  {
    path: "/selection",
    name: "Selections",
    component: Selections
  },
  {
    path: "/selection/:slug",
    name: "Selection",
    component: Selection
  },
  {
    path: "/search/:slug",
    name: "Search",
    component: Search
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
