<template>
  <div class="articleRow" ref="articleRow">
    <div class="item-once" ref="itemOnce">
      <div class="item-slide">
        <div class="item-title title-custom" v-if="owntitle" ref="heading">
          <span>
            {{ item.title_text_1 }}
          </span>
          <span>
            {{ item.title_text_2 }}
          </span>
          <span>
            {{ item.title_text_3 }}
          </span>
        </div>
        <div class="item-title title-from-article" v-if="!owntitle">
          {{ item.article.title }}
        </div>
        <GetImage :image="item.images[0]" size="large" loading="lazy" />
        <router-link
          v-if="item.article_link"
          :to="'/article/' + item.article.slug"
        >
          <div class="articleLink"></div>
        </router-link>
        <a
          v-else-if="item.external_link_url"
          :href="item.external_link_url"
          target="_blank"
        >
          <div class="articleLink"></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import GetImage from "@/components/GetImage.vue";

export default {
  name: "ArticleImagesStatic",
  props: {
    item: undefined,
  },
  computed: {
    owntitle: function() {
      if (this.item && this.item.title_text_1) return true;
      else return false;
    }
  },
  components: {
    GetImage
  },
  methods: {
    heightSet () {
      let height =
        (this.item.images[0].height / this.item.images[0].width) *
        ((window.innerWidth - 30) / 3);
      this.$refs.articleRow.style.setProperty('--rowHeight', height + "px");
    },
    rowSet () {
      var cln1 = this.$refs.itemOnce.cloneNode(true);
      var cln2 = this.$refs.itemOnce.cloneNode(true);
      this.$refs.articleRow.appendChild(cln1);
      this.$refs.articleRow.appendChild(cln2);
    }
  },
  mounted() {
    window.addEventListener("resize", this.heightSet);
    this.heightSet();
    this.rowSet();
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.articleRow {
  display: flex;
  flex-wrap: nowrap;
  .item-once {
    line-height: 0px;
    width: calc(100% / 2);
    @include desktop {
      width: calc(100% / 3);
    }
    .item-slide {
      padding-left: 10px;
      display: inline-block;
      position: relative;
    }
    img {
      height: auto;
      width: 100%;
    }
    @include mobile {
      &:nth-of-type(3) {
        display: none;
      }
    }
  }
  .item-title {
    font-weight: 500;
    overflow: hidden;
    width: calc(1.5 * var(--rowHeight));
    @include desktop {
      width: var(--rowHeight);
    }
    white-space: nowrap;
    background: black;
    text-transform: uppercase;
    position: absolute;
    bottom: 0px;
    left: 10px;
    font-size: 12px;
    letter-spacing: .01em;
    height: 10px;
    line-height: 9px;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    // transition: opacity .2s ease-in-out;
    @include mobile {
      span:nth-of-type(2) {
        display: none;
      }
    }
    @include desktop {
      opacity: 0;
    }
  }
  &:hover {
    .item-title {
      opacity: 1;
    }
  }
  .title-from-article {
    line-height: 12px;
  }
  .title-custom {
    display: flex;
    justify-content: space-between;
  }
  .articleLink {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
</style>
