<template>
  <div id="cover-screen">
    <h1>
      <img src="@/assets/images/studio.svg" alt="STUDIO" />
    </h1>
    <h2 v-if="!$apollo.loading">
      A magazine published biannually showcasing art and fashion in contemporary culture
    </h2>
  </div>
</template>

<script>
export default {
  name: "CoverScreen"
}
</script>

<style lang="scss">
@import "../assets/css/mixins.scss";
#cover-screen {
  position: absolute;
  height: var(--app-height);
  transition: height 0.3s linear;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: -1;
  h1 {
    padding: 5px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    img {
      width: 100%;
      transform: scale(var(--scale));
      visibility: var(--visibility);
      transform-origin: bottom center;
    };
    @include desktop {
      padding: 10px;
    }
  }
  h2 {
    position: fixed;
    top: var(--top);
    left: 0;
    font-weight: 300;
    font-size: 48px;
    line-height: .9em;
    letter-spacing: -.04em;
    padding: 5px 10px;
    max-width: 700px;
  }
  @include mobile {
    h2 {
      font-size: 30px;
      letter-spacing: -.03em;
      padding: 10px;
    }
  }
}
</style>