import gql from 'graphql-tag'

export const GET_FOOTER = gql`
  query Footer {
    footer {
      id
      columns {
        id
        text
      }
    }
  }
`