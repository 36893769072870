<template>
  <div id="searchresults" v-if="search">
    <PageTitle :title="'Search'" />
    <Searchform :searchterm="this.routeParam" />
    <SubTitle :title="search.slice().length + ' Results'" />
    <div class="articles">
      <ArticleListEntry
        v-for="article in search.slice().reverse()"
        v-bind:article="article"
        :key="'article-' + article.id"
        v-bind:date="true"
      />
    </div>
  </div>
</template>

<script>
import { GET_SEARCH } from "@/graphql/queries/getSearch.js";
import PageTitle from "@/components/PageTitle.vue";
import ArticleListEntry from "@/components/ArticleListEntry.vue";
import Searchform from "@/components/Searchform.vue";
import SubTitle from "@/components/SubTitle.vue";

export default {
  name: "Search",
  data() {
    return {
      routeParam: this.$route.params.slug
    };
  },
  props: {
    searchterm: String
  },
  apollo: {
    search: {
      query: GET_SEARCH,
      variables() {
        return {
          searchterm: this.routeParam
        };
      }
    }
  },
  components: {
    PageTitle,
    ArticleListEntry,
    Searchform,
    SubTitle
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

#searchresults {
  padding: 0 10px;
  font-weight: 500;
  .articles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }
}
</style>