<template>
  <div class="contributors">
    <h2>{{ item.title }}</h2>
    <div class="all-contributors">
      <div
        v-for="(contrib, index) in item.contributors"
        class="contributor"
        :key="'contrib-' + index"
      >
        <a v-if="contrib.url" :href="contrib.url" target="_blank">
          {{ contrib.name }}
        </a>
        <span v-else>
          {{ contrib.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contributors",
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";
.contributors {
  padding: 10px;
  h2 {
    font-weight: 400;
    font-size: 55px;
    line-height: 47px;
    letter-spacing: -0.02em;
  }
  .all-contributors {
    columns: 2;
    margin-top: 1em;
    font-size: 23px;
    line-height: 100%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    * {
      -webkit-column-break-inside: avoid;
    }
    a {
      display: block;
      text-decoration: none;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  @include desktop {
    display: flex;
    flex-wrap: nowrap;
    h2 {
      width: calc(100% / 3);
      font-size: 60px;
      line-height: 50px;
      letter-spacing: -0.02em;
      padding-right: 50px;
    }
    .all-contributors {
      margin-top: 0;
      width: calc(100% / 1.5);
      column-width: 50%;
      column-gap: 20px;
      font-size: 30px;
      line-height: 100%;
      letter-spacing: 0.01em;
    }
  }
}
</style>