import gql from 'graphql-tag'

export const GET_SELECTION = gql`
  query Selection($slug: String!) {
    selection:selections (where: { slug: $slug }) {
      id
      slug
      Name
      slider {
        id
        slides {
          id
          text
          image {
            id
            url
            width
            height
            alternativeText
            formats
          }
        }
        textslide
      }
    }
  }
`