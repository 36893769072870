<template>
  <div id="footer" v-if="!$apollo.loading">
    <div id="issue-cover" v-if="issue.cover.length">
      <GetImage
        :image="issue.cover[Math.floor(Math.random()*issue.cover.length)]"
        loading="lazy"
      />
      <button @click="$router.push('/shop')">
        <span>ORDER NOW</span>
      </button>
    </div>
    <!-- <Menu id="menu-footer" class="desktop" v-bind:menu="menu" /> -->
    <div id="footer-columns" v-if="footer">
      <div v-for="item in footer.columns" :key="item.id">
        <p v-html="item.text"></p>
      </div>
      <div @click="scrolltop" class="scrolltop">
        Back to start
      </div>
    </div>
  </div>
</template>

<script>
import { GET_FOOTER } from "@/graphql/queries/getFooter.js";

// import Menu from "@/components/Menu.vue";
import GetImage from "@/components/GetImage.vue";

export default {
  name: "Footer",
  data() {
    return {}
  },
  apollo: {
    footer: {
      query: GET_FOOTER
    }
  },
  props: {
    menu: Array,
    issue: Object,
  },
  components: {
    // Menu,
    GetImage
  },
  methods: {
    scrolltop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/css/mixins.scss";

#footer {
  max-width: 100vw;
  overflow: hidden;
  background: black;
  #menu-footer {
    margin-bottom: 25px;
    .link-wrapper {
      width: max-content;
      min-width: 100vw;
      position: relative;
      top: 30px;
      overflow: hidden;
      &:hover {
        a {
          left: calc(100vw - 100%);
        }
      }
    }
    a {
      display: block;
      letter-spacing: -0.04em;
      font-size: 455px;
      line-height: 370px;
      font-weight: 300;
      width: max-content;
      position: relative;
      left: 0px;
      top: -30px;
      transition: left 2s linear;
    }
  }
  #issue-cover {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  #footer-columns {
    padding: 10px;
    font-weight: 500;
    a {
      text-decoration: none;
      &:hover {
        color: var(--current-color);
      }
    }
    .scrolltop {
      cursor: pointer;
      &:hover {
        color: var(--current-color);
      }
    }
    div {
      white-space: pre-line;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      @include desktop {
        font-size: 16px;
        line-height: 18px;
      }
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @include desktop {
      display: flex;
      justify-content: space-between;
      div {
        margin-bottom: 0;
      }
    }
  }
}
</style>
