import gql from 'graphql-tag'

export const GET_PLAYLIST = gql`
  query Playlists($slug: String!) {
    playlists (where: { slug: $slug }) {
      id
      title
      title_1
      title_2
      title_3
      description
      cover {
        id
        url
        width
        height
        alternativeText
        formats
      }
      track {
        id
        title
        artist
        label
        url
      }
    }
  }
`