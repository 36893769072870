<template>
  <div class="articleRow">
    <ArticleImages
      v-if="item.__typename == 'ComponentBlocksArticleImages'"
      v-bind:item="item"
      v-bind:even="even"
    />
    <ArticleImagesStatic
      v-if="item.__typename == 'ComponentBlocksArticleImagesStatic'"
      v-bind:item="item"
    />
    <ArticleBig 
      v-if="item.__typename == 'ComponentBlocksArticleBig'"
      v-bind:item="item"
    />
    <Playlist 
      v-if="item.__typename == 'ComponentBlocksPlaylist'"
      v-bind:item="item.playlist"
    />
    <Newsletter 
      v-if="item.__typename == 'ComponentBlocksNewsletter'"
      v-bind:item="item"
    />
    <Contributors 
      v-if="item.__typename == 'ComponentBlocksContributors'"
      v-bind:item="item"
    />
    <YoutubeEmbed 
      v-if="item.__typename == 'ComponentBlocksYoutubeEmbed'"
      v-bind:item="item"
    />
  </div>
</template>

<script>
import ArticleImages from "@/components/blocks/ArticleImages.vue";
import ArticleImagesStatic from "@/components/blocks/ArticleImagesStatic.vue";
import ArticleBig from "@/components/blocks/ArticleBig.vue";
import Playlist from "@/components/blocks/Playlist.vue";
import Newsletter from "@/components/blocks/Newsletter.vue";
import Contributors from "@/components/blocks/Contributors.vue";
import YoutubeEmbed from "@/components/blocks/YoutubeEmbed.vue";

export default {
  name: "ArticleBlock",
  data() {
    return {}
  },
  props: {
    item: undefined,
    even: undefined
  },
  components: {
    ArticleImages,
    ArticleImagesStatic,
    ArticleBig,
    Playlist,
    Newsletter,
    Contributors,
    YoutubeEmbed
  }
};
</script>

<style scoped lang="scss"></style>
