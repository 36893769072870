<template>
  <div class="newsletter">
    <h2>NEWS FROM STUDIO</h2>
    <!-- Begin Mailchimp Signup Form -->
    <div id="mc_embed_signup">
      <form
        action="https://studi.us2.list-manage.com/subscribe/post?u=0eba88a15f47c207eea73d113&amp;id=d3a3492646"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll">
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address </label>
            <input
              type="email"
              value=""
              name="EMAIL"
              class="required email"
              id="mce-EMAIL"
              placeholder="NEWSLETTER"
            />
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style="display:none"
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style="display:none"
            ></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input
              type="text"
              name="b_0eba88a15f47c207eea73d113_d3a3492646"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="clear">
            <input
              type="submit"
              value="Sign up"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
            />
          </div>
        </div>
      </form>
    </div>
    <!--End mc_embed_signup-->
  </div>
</template>

<script>
export default {
  name: "Newsletter",
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";
.newsletter {
  position: relative;
  background: var(--current-color);
  color: black;
  padding: 10px;
  overflow: hidden;
  @include desktop {
    // height: 280px;
  }
  h2 {
    font-size: 55px;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    @include desktop {
      text-transform: none;
      font-size: 18px;
      line-height: 110%;
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
  label {
    display: none;
  }
  .field {
    margin: 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: 5px solid black;
    display: flex;
  }
  input {
    font-family: basel, sans-serif;
    font-size: 55px;
    line-height: 60px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.02em;
    height: 100%;
    display: block;
    max-width: 100%;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 0px;
    flex-grow: 1;
    outline: none;
    border-bottom: 3px solid black;
    margin: 1em auto 0;
    overflow: show;
    &::placeholder {
      font-family: basel, sans-serif;
      color: black;
      text-align: center;
      opacity: 1;
      @include desktop {
        position: relative;
        left: -1vw;
      }
      @include mobile {
        display: none;
      }
    }
    @include desktop {
      margin: 0;
      font-size: calc(100vw / 6);
      line-height: calc(100vw / 6);
      letter-spacing: -0.035em;
      font-weight: 300;
      // position: absolute;
      // bottom: 35px;
      // left: 10px;
      position: relative;
      bottom: 25px;
      left: 0px;
      width: calc(100vw - 10px);
      height: 1em;
      text-transform: uppercase;
    }
  }
  .button {
    text-transform: uppercase;
    border: none;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.03em;
    margin: 2em auto 1em;
    font-weight: 500;
    @include desktop {
      font-size: 18px;
      line-height: 110%;
      position: absolute;
      bottom: 10px;
      right: 10px;
      left: unset;
      margin: 0;
      height: 1em;
      width: auto;
    }
  }
}
</style>