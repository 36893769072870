<template>
  <div
    :class="[
      { showState: !showStuff },
      'articleRow',
      'articleBig',
      item.color,
      item.font
    ]"
    @mouseleave="showStuff = true"
  > 
    <h2>{{ item.article.title }}</h2>
    <div class="buttonzone">
      <router-link
        v-if="item.article_link"
        :to="'/article/' + item.article.slug"
      >
        <button
          v-if="item.buttonText"
          @mouseover="uncover"
          @mouseleave="recover"
        >
          <span>{{ item.buttonText }}</span>
        </button>
      </router-link>
      <button
        v-else-if="item.buttonText"
        @mouseover="uncover"
        @mouseleave="recover"
        @click="showStuff = false"
      >
        <span>{{ item.buttonText }}</span>
      </button>
    </div>
    <div class="subtitle">
      {{ item.article.subtitle }}
    </div>
    <Credits :credits="item.article.credits" />
    <div v-if="item.show == 'cover'" class="background">
      <GetImage
        v-if="item.article.cover"
        :image="item.article.cover"
        size="large"
        loading="lazy"
      />
    </div>
    <div v-else-if="item.show == 'hero'" class="background">
      <GetImage
        v-if="item.article.hero_image"
        :image="item.article.hero_image"
        size="large"
        loading="lazy"
      />
    </div>
    <div
      v-else-if="item.show == 'video'"
      v-bind:class="[{ uncover: !showStuff }, 'background']"
    >
      <ShowVideo :embed="item.article.video" />
    </div>
    <div v-else-if="item.show == 'slider'" class="background">
      <Slider :slider="item.article.slider" />
    </div>
  </div>
</template>

<script>
import GetImage from "@/components/GetImage.vue";
import ShowVideo from "@/components/ShowVideo.vue";
import Slider from "@/components/Slider.vue";

import Credits from "@/components/Credits.vue";
export default {
  data() {
    return {
      showStuff: true
    }
  },
  props: {
    item: undefined
  },
  components: {
    Credits,
    GetImage,
    ShowVideo,
    Slider
  },
  methods: {
    uncover() {
      this.$el.querySelector(".background").classList.add("uncover");
    },
    recover() {
      this.$el.querySelector(".background").classList.remove("uncover");
    },
    mobileUncover() {
      this.$el.querySelector(".background").classList.add("mobUncover");
    },
    mobileRecover() {
      this.$el.querySelector(".background").classList.remove("mobUncover");
    }
  },
  mounted() {
    const articleBig = this;
    var observer = new IntersectionObserver(function(entries) {
        if (entries[0]["isIntersecting"] === true) {
          if (entries[0]["intersectionRatio"] > 0.85)
            articleBig.mobileUncover();
          else articleBig.mobileRecover();
        } else {
          articleBig.mobileRecover();
        }
      }, { threshold: [0, 0.85, 1] });
    observer.observe(this.$el);
  }
}
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

.articleBig {
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2, .subtitle, .buttonzone {
    text-align: center;
  }
  h2,
  .subtitle,
  .buttonzone,
  .credits {
    z-index: 10;
  }
  h2 {
    text-transform: uppercase;
    padding: 20px;
  }
  .buttonzone {
    padding: 20px;
    margin: auto 0;
    a {
      text-decoration: none;
    }
    @include desktop {
      padding: 100px 20px;
    }
  }
  .subtitle {
    padding: 20px 20px 10px;
    @include desktop {
    }
  }
  @include mobile {
    .buttonzone {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    h2 {
      margin-bottom: auto;
    }
    .subtitle {
      margin-top: auto;
    }
  }
  &.sans {
    h2 {
      padding: 20px 10px;
      font-size: 67px;
      line-height: 60px;
      letter-spacing: -0.03em;
      font-weight: 300;
      @include desktop {
        font-size: 175px;
        line-height: 130px;
      }
    }
    .subtitle {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      @include desktop {
        font-size: 55px;
        line-height: 50px;
        letter-spacing: 0em;
      }
    }
  }
  &.serif,
  &.serifEmbossed {
    font-family: timesnow, serif;
    h2 {
      font-size: 54px;
      line-height: 46px;
      letter-spacing: -0.02em;
      @include desktop {
        font-size: 160px;
        line-height: 125px;
        letter-spacing: -0.03em;
        margin-top: 10px;
      }
    }
    .subtitle {
      font-size: 23px;
      line-height: 23px;
      letter-spacing: -0.01em;
      @include desktop {
        font-size: 60px;
        line-height: 55px;
        letter-spacing: -0.02em;
      }
    }
  }
  &.whiteOnBlack {
    button {
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
    }
  }
  &.blackOnWhite {
    color: black;
    background: white;
    button {
      background: white;
    }
  }
  &.serifEmbossed.whiteOnBlack {
    h2,
    .subtitle {
      background-color: rgba(255, 255, 255, 0.6);
      color: transparent;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
    } 
  }
  &.serifEmbossed.blackOnWhite {
    h2,
    .subtitle {
      background-color: rgba(0, 0, 0, 0.6);
      color: transparent;
      text-shadow: 0px 2px 2px rgba(255, 255, 255, 1);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
    }  
  }
  .credits {
    font-weight: 400;
    font-family: basel, sans-serif;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0;
    padding: 10px;
    .person {
      text-transform: uppercase;
    }
    .agency {
      font-weight: 500;
    }
    @include desktop {
      font-size: 30px;
      line-height: 30px;
      letter-spacing: -0.02em;
    }
  }
  .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include mobile {
      padding: 20px;
    }
    filter: blur(20px);
    transition: filter 0.2s linear, opacity 0.2s linear;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    &.uncover {
      filter: none;
    }
    &.mobUncover {
      @include mobile {
        filter: none;
      }
    }
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .vimeo-video {
      max-width: 1280px;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.serifEmbossed {
    .background {
      opacity: 0;
      &.uncover {
        opacity: 1 !important;
      }
      &.mobUncover {
        @include mobile {
          opacity: 1 !important;
          filter: none;
        }
      }
    }
  }
  > * {
    transition: opacity .5s linear;
  }
  &.showState {
    > * {
      opacity: 0;
      pointer-events: none;
    }
    .background {
      opacity: 1;
      filter: none;
      pointer-events: unset;
    }
  }
}
</style>