<template>
  <router-link to="/">
    <div id="subSuccess">
      <div id="thanks">Thank you<br>for your order</div>
      <div id="back">Back to Studio</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    details: undefined
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

#subSuccess {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  height: 100vh;
  height: var(--app-height);
  width: 100vw;
  background: var(--current-color);
  text-align: center;
  color: black;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  #thanks {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.02em;
    margin: auto 0 0;
    @include desktop {
      font-size: 60px;
      line-height: 60px;
    }
  }
  #back {
    font-size: 20px;
    line-height: 90%;
    letter-spacing: 0.03em;
    margin: auto 0 150px;
    @include desktop {
      margin-bottom: 30px;
    }
  }
}

</style>