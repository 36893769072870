<template>
  <div id="shopModal">
    <div id="modalClose">
      <img
        src="@/assets/images/x.svg"
        alt="Close"
        @click="$emit('modal-close')"
      />
    </div>
    <div class="title">{{ product.node.title }}</div>
    <img
      class="product-image"
      :src="productImage"
      :alt="product.node.images.edges[0].node.altText"
      v-if="product.node.images.edges[0]"
    />
    <div class="thumbnails" v-if="product.node.images.edges[0]">
      <div class="thumb-wrap">
        <img
          v-for="(image, index) in product.node.images.edges"
          :key="image.node.id"
          :src="image.node.src"
          :alt="image.node.altText"
          :class="{ selected: index === 0 }"
          @click="changeImg($event, image.node)"
          @mouseover="changeImg($event, image.node)"
        />
      </div>
    </div>
    <div class="description" v-html="product.node.descriptionHtml" />
    <select
      v-model="selected"
      v-if="product.node.variants.edges.length > 1"
      required
    >
      <option value="" disabled>Pick option:</option>
      <option
        v-for="variant in product.node.variants.edges"
        :key="variant.node.id"
        :value="variant.node"
      >
        {{ variant.node.title }} — {{ variant.node.price.currencyCode }} {{ variant.node.price.amount * 1 }}
      </option>
    </select>
    <div v-else class="price">{{ product.node.variants.edges[0].node.price.currencyCode }} {{ product.node.variants.edges[0].node.price.amount * 1 }}</div>
    <button
      v-if="product.node.availableForSale && (product.node.variants.edges.length == 1 | selected != '')"
      id="addToBag"
      @click="$emit('add-to-bag', selected, product.node, collection.node)"
    >
      Add to bag
    </button>
  </div>
</template>

<script>
export default {
  name: "ShopModal",
  data() {
    return {
      selected: this.product.node.variants.edges[0].node,
      productImage: this.product.node.images.edges[0].node.src
    };
  },
  props: {
    product: Object,
    collection: Object
  },
  methods: {
    changeImg(event, image) {
      this.productImage = image.src;
      this.$el
        .getElementsByClassName("selected")
        .forEach(elem => elem.classList.remove("selected"));
      event.target.classList.add("selected");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

#shopModal {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: black;
  overflow: scroll;
  padding: 10px 10px 50px;
  font-size: 16px;
  line-height: 1.2em;
  #modalClose {
    position: absolute;
    right: 12px;
    top: 12px;
    text-align: right;
    margin: 0 0 auto;
    cursor: pointer;
    img {
      @include mobile {
        height: 52px;
        width: 52px;
      }
      display: inline-block;
    }
  }
  .title {
    margin-bottom: 2.6em;
  }
  .description {
    margin: 1.2em 0;
    line-height: 1.2em;
  }
  .price {
    order: 4;
    padding-bottom: 20px;
    margin-bottom: auto;
  }
  .thumbnails {
    height: auto;
    width: auto;
    .thumb-wrap {
      background: var(--current-color);
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: auto;
      @include desktop {
        flex-direction: column;
      }
    }
    img {
      @include mobile {
        height: 82px;
        width: auto;
      }
      @include desktop {
        width: 60px;
        height: auto;
      }
      object-fit: contain;
      object-position: top left;
      display: block;
    }
    .selected {
      filter: grayscale(100%);
      mix-blend-mode: multiply;
    }
  }
  select,
  button {
    width: 100vw;
    margin: 0 -10px;
    padding: 15px 10px;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: none;
    background: var(--current-color);
    border-radius: 0px;
    flex-grow: 1;
    outline: none;
    box-shadow: none;
    @include desktop {
      font-size: 45px;
      line-height: 45px;
      font-weight: 300;
    }
  }
  button {
    margin: 0;
    margin-bottom: 75px;
    color: white;
    background: black;
    display: block;
    width: 100%;
    height: auto;
    text-align: left;
    padding: 15px 0;
    position: initial;
    border-bottom: 2px solid white;
    &:hover {
      color: black;
    }
  }
  @include desktop {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
    font-size: 20px;
    .product-image {
      width: calc(50% - 40px);
      height: 100%;
      order: 2;
      object-fit: contain;
      object-position: top left;
    }
    .thumbnails {
      order: 1;
      height: 100%;
      width: 60px;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    .title {
      order: 3;
      height: auto;
      width: calc(50% - 120px);
      padding-left: 10px;
    }
    .description {
      order: 4;
      width: calc(50% - 120px);
      // margin-bottom: auto;
      padding-left: 10px;
      font-size: 22px;
      font-weight: 400;
    }
    .price {
      padding-left: 10px;
    }
    select {
      margin: auto 10px 0 10px;
      order: 5;
      width: calc(50% - 30px);
      flex-grow: 0;
      padding-left: 10px;
    }
    button {
      order: 6;
      width: auto;
      width: calc(50% - 30px);
      flex-grow: 0;
      margin-bottom: 0;
      margin-left: 10px;
      font-size: 45px;
    }
    #addToBag {
      padding-left: 10px;
      margin-top: 20px;
    }
  }
}
</style>