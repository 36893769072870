var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.category)?_c('div',{class:'category-' + this.routeParam,attrs:{"id":"category"}},[_c('PageTitle',{attrs:{"title":_vm.category[0].name}}),(
      this.routeParam != 'exhibitions' &&
        _vm.category[0].articles
          .slice()
          .reverse()
          .filter(elem => _vm.lessThanFiveDaysAgo(elem.created_at))[0])?_c('div',{staticClass:"articles newest"},_vm._l((_vm.category[0].articles.slice().reverse().filter(elem => _vm.lessThanFiveDaysAgo(elem.created_at))),function(article){return _c('ArticleListEntry',{key:'article-' + article.id,attrs:{"article":article,"date":true}})}),1):_vm._e(),(
      this.routeParam != 'exhibitions' &&
        _vm.category[0].articles
          .slice()
          .reverse()
          .filter(elem => !_vm.lessThanFiveDaysAgo(elem.created_at))[0])?_c('div',{staticClass:"articles"},_vm._l((_vm.category[0].articles.slice().reverse().filter(elem => !_vm.lessThanFiveDaysAgo(elem.created_at))),function(article){return _c('ArticleListEntry',{key:'article-' + article.id,attrs:{"article":article,"date":true}})}),1):_c('div',[(
        _vm.category[0].articles
          .slice()
          .reverse()
          .filter(elem => _vm.isUpcoming(elem.start))[0]
      )?_c('SubTitle',{attrs:{"title":'Upcoming'}}):_vm._e(),_c('div',{staticClass:"articles"},_vm._l((_vm.category[0].articles.slice().reverse().filter(elem => _vm.isUpcoming(elem.start))),function(article){return _c('ArticleListEntry',{key:'article-' + article.id,staticClass:"upcoming",attrs:{"article":article,"startEnd":true}})}),1),(
        _vm.category[0].articles
          .slice()
          .reverse()
          .filter(elem => _vm.isHappening(elem.start, elem.end))[0])?_c('SubTitle',{attrs:{"title":'Current'}}):_vm._e(),_c('div',{staticClass:"articles"},_vm._l((_vm.category[0].articles.slice().reverse().filter(elem => _vm.isHappening(elem.start, elem.end))),function(article){return _c('ArticleListEntry',{key:'article-' + article.id,staticClass:"current",attrs:{"article":article,"startEnd":true}})}),1),(
        _vm.category[0].articles
          .slice()
          .reverse()
          .filter(elem => _vm.isOver(elem.end))[0]
      )?_c('SubTitle',{attrs:{"title":'Past'}}):_vm._e(),_c('div',{staticClass:"articles"},_vm._l((_vm.category[0].articles.slice().reverse().filter(elem => _vm.isOver(elem.end))),function(article){return _c('ArticleListEntry',{key:'article-' + article.id,staticClass:"past",attrs:{"article":article,"startEnd":true}})}),1)],1),(this.routeParam == 'music-culture')?_c('SubTitle',{attrs:{"title":'Playlists'}}):_vm._e(),(this.routeParam == 'music-culture')?_c('CategoryPlaylists'):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }