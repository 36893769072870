<template>
  <div id="shop" v-if="!$apollo.queries.collections.loading">
    <PageTitle title="Shop" />
    <div class="products">
      <div
        v-for="collection in Object.values(
          collections.edges
        ).filter(collection => notHidden(collection.node.id))"
        :key="'list-' + collection.node.id"
        :class="[
          'collection',
          'collection-' + collection.node.handle,
          collection.node.id
        ]"
      >
        <div
          v-for="product in Object.values(
            collection.node.products.edges
          ).filter(product => {
            return product.node.images.edges[0];
          })"
          :key="'product-' + product.node.id"
          @click="modalOpen(product, collection)"
          :class="[
            'product',
            { 'product-checkout': productIsInCart(product.node) },
            { soldout: !product.node.availableForSale }
          ]"
        >
          <div class="imagewrap">
            <img :src="product.node.images.edges[0].node.src" />
          </div>
          <div class="title">
            {{ product.node.title }}
          </div>
          <div class="description" v-html="product.node.descriptionHtml" />
          <div class="price">{{ product.node.variants.edges[0].node.price.currencyCode }} {{ product.node.variants.edges[0].node.price.amount * 1 }}</div>
        </div>
      </div>
    </div>
    <div class="shop-menu">
      <div
        class="collections-expand"
        @click="expandCollections = !expandCollections"
        :class="{ active: filterCollection.length > 0 }"
      >
        Categories
      </div>
      <div
        class="collections"
        v-if="collections"
        :class="{ expanded: expandCollections }"
      >
        <div
          v-if="collections.edges.length > 1"
          :class="[
            'menu-item',
            'menu-item-collection',
            { 'menu-item-active': activeFilter('all') }
          ]"
          @click="filterBy()"
        >
          All
        </div>
        <div
          v-for="collection in collections.edges"
          :key="'link-' + collection.node.id"
          :class="[
            'menu-item',
            'menu-item-collection',
            { 'menu-item-active': activeFilter(collection.id) }
          ]"
          @click="filterBy(collection.id)"
        >
          {{ collection.node.title }}
        </div>
      </div>
      <div :class="[
          'menu-item',
          'menu-item-checkout',
          { 'checkout-active': hasCart() }
        ]"
        @click="shopCart = true;"
      >
        <span class="checkout-button">Cart</span>
        <span :class="['cart-amount', { 'highlight': cartHighlight }]">{{
          this.cartLength()
        }}</span>
      </div>
    </div>
    <ShopModal
      v-if="modalIsOpen"
      v-on:add-to-bag="addToCart"
      v-on:modal-close="modalClose"
      :product="modalProduct"
      :collection="modalCollection"
    />
    <ShopCart
      v-if="shopCart"
      v-on:checkout="this.doCheckout"
      v-on:cart-close="this.cartClose"
      v-on:increase="this.increaseAmount"
      v-on:decrease="this.decreaseAmount"
      :cart="this.$store.state.cart"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { GET_CATEGORIES } from "@/graphql/queries/shopify/getCategories.js";
// import Client from "shopify-buy";
import ShopModal from "@/components/ShopModal.vue";
import ShopCart from "@/components/ShopCart.vue";
import PageTitle from "@/components/PageTitle.vue"

function sortByKey(array, key) {
  return array.sort(function(a, b) {
    var x = a[key]; var y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}
export default {
  name: "Shop",
  data() {
    return {
      routeParam: this.$route.params.slug,
      filterCollection: "",
      modalIsOpen: false,
      shopCart: false,
      modalProduct: Object,
      modalCollection: Object,
      expandCollections: false,
      checkoutUrl: String,
      cartHighlight: false
    };
  },
  props: {
    slug: String
  },
  apollo: {
    collections: {
      query: GET_CATEGORIES,
      client: "apolloShopifyClient",
      result(res) {
        res.data.collections.edges = sortByKey(
          res.data.collections.edges,
          "node.title"
        );
        // open modal if we are inside a product
        if (this.routeParam) {
          var slug = this.routeParam;
          // console.log("routeParam: " + slug);
          var selectedProduct;
          var selectedCollection;
          res.data.collections.edges.forEach(function(coll) {
            coll.node.products.edges.forEach(function(prod) {
              if (prod.node.handle == slug) {
                selectedProduct = prod;
                selectedCollection = coll;
              }
            });
          });
          this.modalOpen(selectedProduct, selectedCollection);
        }
      }
    }
  },
  components: {
    ShopModal,
    ShopCart,
    PageTitle
  },
  methods: {
    modalOpen(product, collection) {
      if (this.routeParam) {
        this.modalProduct = product;
        this.modalCollection = collection;
        this.modalIsOpen = true;
      } else {
        this.$router.push({
          path: `/shop/${product.node.handle}`
        });
      }
    },
    modalClose(newItem) {
      // this.modalIsOpen = false;
      if (newItem) {
        this.$router.push({
          name: "Shop",
          params: { newItemInCart: true }
        });
      } else {
        this.$router.push({
          // path: "/shop/"
          name: "Shop"
        });
      }
    },
    cartBlink() {
      this.cartHighlight = !this.cartHighlight;
      setTimeout(() => {
        this.cartHighlight = !this.cartHighlight;
      }, 10);
    },
    cartClose() {
      this.shopCart = false;
    },
    hasCart() {
      return this.$store.state.cart.length != 0
    },
    cartLength() {
      return Object.keys(this.$store.state.cart).length;
    },
    productIsInCart(product) {
      var productInCart = false;
      product.variants.edges.forEach(variant => {
        if (this.variantIsInCart(variant.node)) productInCart = true;
      });
      this.$store.state.cart.forEach(entry => {
        if (entry.product == product.node) {
          productInCart = true;
        }
      });
      return productInCart;
    },
    variantIsInCart(variant) {
      var isInCart = false;
      if (this.$store.state.cart.length > 0) {
        this.$store.state.cart.forEach(entry => {
          if (entry.variant == variant) isInCart = true;
        });
      }
      return isInCart;
    },
    addToCart(selected, product, collection) {
      if (selected == "") {
        console.log("no variant selected, selecting first variant")
        selected = product.node.variants.edges[0].node;
      }
      if (this.variantIsInCart(selected)) {
        console.log("is in cart, increasing")
        this.increaseAmount(selected);
        console.log("Cart: " + this.$store.state.cart[0].amount);
      } else {
        console.log("is not in cart, adding");
        var payload = {
          amount: 1,
          product: product,
          variant: selected,
          collection: collection
        };
        this.$store.commit("addToCart", payload);
        console.log("Cart: ");
        console.log(this.$store.state.cart);
      }
      this.modalClose(true);
    },
    increaseAmount(variant) {
      if (this.variantIsInCart(variant)) {
        this.$store.state.cart.forEach((entry, index) => {
          if (entry.variant == variant) {
            this.$store.commit("increase", index);
          }
        });
      }
    },
    decreaseAmount(variant) {
      if (this.variantIsInCart(variant)) {
        this.$store.state.cart.forEach((entry, index) => {
          if (entry.variant == variant) {
            this.$store.commit("decrease", index);
          }
        });
      }
    },
    filterBy(id) {
      // apply filter
      if (!id) {
        this.filterCollection = "";
      } else if (id == this.filterCollection) {
        this.filterCollection = "";
      } else {
        this.filterCollection = id;
      }
      var container = this.$el.querySelectorAll(".products")[0];
      container.scrollIntoView();
      this.expandCollections = false;
    },
    cartToCheckout(callback) {
      // const checkoutId = this.checkout.id;
      const lineItemsToAdd = [];
      // this.state.cart.forEach(entry => {
      this.$store.state.cart.forEach(entry => {
        if (entry.variant.sellingPlanAllocations.edges.length == 0) {
          lineItemsToAdd.push({
            quantity: entry.amount,
            merchandiseId: entry.variant.id
          });
        } else {
          lineItemsToAdd.push({
            quantity: entry.amount,
            merchandiseId: entry.variant.id,
            sellingPlanId: entry.variant.sellingPlanAllocations.edges[0].node.sellingPlan.id
          });
        }
      });
      // NEW shopify storefront API
      this.$apollo.mutate(
        {
          // Query
          mutation: gql`
            mutation ($input: CartInput!) {
              cartCreate(input: $input) {
                cart {
                  id
                  checkoutUrl
                }
                userErrors {
                  code
                  field
                  message
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              lines: lineItemsToAdd
            }
          },
          client: "apolloShopifyClient"
        })
        .then(data => {
          // Result
          callback(data.data.cartCreate.cart.checkoutUrl);
        })
        .catch(error => {
          // Error
          console.error(error);
        });
    },
    doCheckout() {
      const shop = this;
      var win = window.open("", "_blank");
      if (this.hasCart) {
        shop.cartToCheckout(function(url) {
          // var url = shop.checkoutUrl;
          win.location.assign(url);
          console.log(url);
        });
      }
    },
    notHidden(id) {
      // check if a category is not hidden, used for filtering collections
      if (!this.filterCollection) {
        return true;
      } else if (id == this.filterCollection) {
        return true;
      } else return false;
    },
    activeFilter(id) {
      // check if an id is currently active as filter, used for styling menu
      if (
        id == this.filterCollection ||
        (id == "all" && this.filterCollection == "")
      )
        return true;
      else return false;
    },
  },
  mounted() {
    if (this.$route.params.newItemInCart) {
      this.cartBlink();
    }
    // TODO: create cart and use for replaced functions
    // use vuex state only to store cart ID
  }
};
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";

#shop {
  padding: 0 10px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
  .shop-menu {
    display: flex;
    background: var(--current-color);
    color: black;
    text-transform: uppercase;
    position: sticky;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    margin: 0 -10px;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    .collections {
      display: flex;
      flex-direction: row;
      @include mobile {
        height: 0px;
        overflow: hidden;
        &.expanded {
          height: auto;
          width: calc(100vw - 0px);
        }
        flex-direction: column;
        position: absolute;
        left: 0px;
        bottom: 100%;
        background: var(--current-color);
      }
    }
    .collections-expand {
      @include desktop {
        display: none;
      }
      &.active {
        color: white;
      }
      padding: 20px 10px 20px 10px;
      user-select: none;
      cursor: pointer;
    }
    .menu-item {
      padding: 15px 10px;
      user-select: none;
      cursor: pointer;
      @include mobile {
        border-bottom: 2px solid black;
        height: 56px;
        line-height: 24px;
      }
      &.menu-item-checkout {
        border-bottom: none;
        border-left: 20px solid black;
        margin-left: auto;
        cursor: unset;
        @include mobile {
          width: calc(50% + 5px);
          padding: 16px 10px 20px 20px;
          // border-left: 10px solid black;
          border: none;
          text-align: right;
          padding-right: 60px;
        }
        &.checkout-active {
          cursor: pointer;
        }
      }
      &:hover,
      &.menu-item-active {
        color: white;
      }
    }
    .cart-amount {
      color: var(--current-color);
      background: black;
      border-radius: 20px;
      height: 35px;
      width: 35px;
      text-align: center;
      display: block;
      position: absolute;
      right: 11px;
      top: 11px;
      padding-top: 5px;
      transform-origin: bottom right;
      transition: all .5s ease-in;
    }
    .cart-amount.highlight {
      color: black;
      background: var(--current-color);
      transform: translate(0, -600px) scale(5);
      transition: none;
    }
    @include desktop {
      font-size: 20px;
      line-height: 20px;
      .menu-item {
        padding: 20px 17px 20px 10px;
        &.menu-item-checkout {
          margin-right: none;
          position: relative;
          width: calc(25% + 12px);
        }
      }
      .cart-amount {
        padding-top: 6px;
      }
    }
  }
  .collection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .products {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    .product {
      position: relative;
      margin: 5px;
      margin-bottom: 2.6em;
      cursor: pointer;
      width: calc(50% - 10px);
      .title {
        margin: 5px 0 1.2em;
      }
      .description {
        margin-bottom: 2.6em;
        line-height: 1.2em;
      }
      .price {
        position: absolute;
        bottom: 0px;
      }
      .imagewrap {
        position: relative;
        img {
          width: 100%;
          height: calc(calc(50vw - 10px) * 1.36);
          object-fit: cover;
          @include desktop {
            height: calc(calc(25vw - 20px) * 1.36);
          }
        }
      }
      &:hover {
        .imagewrap {
          background: var(--current-color);
        }
        img {
          filter: grayscale(100%);
          mix-blend-mode: multiply;
        }
      }
      &.soldout {
        > * {
          opacity: .2;
        }
        &::before {
          content: "SOLD OUT";
          display: block;
          position: absolute;
          bottom: 120px;
          left: 0px;
          text-align: center;
          font-size: 66px;
          line-height: 60px;
          color: white;
          z-index: 1;
          font-weight: 300;
          @include desktop {
            bottom: 70px;
            font-size: 102px;
            line-height: 90px;
          }
        }
      }
      @include desktop {
        margin: 10px 10px 2.6em 10px;
        width: calc(25% - 20px);
        .title {
          margin: 10px 0 1.2em;
        }
      }
    }
    .product-checkout {
      .imagewrap::before {
        // outline: 10px solid var(--current-color);
        content: "";
        display: block;
        z-index: 10;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 0px 0px 5px var(--current-color);
      }
    }
  }
  .collection-subscription {
    .product {
      width: 100%;
      @include desktop {
        width: calc(50% - 20px);
      }
      img {
        object-fit: contain !important;
        object-position: top left;
      }
    }
  }
}
</style>