<template>
  <div id="subscription" v-if="!$apollo.queries.subscription.loading">
    <PageTitle :title="subscription.title" /> 
    <div class="subscription-products">
      <div
        v-for="(item, index) in subscription.subscriptionProduct"
        :key="item.id"
        class="product"
      >
        <GetImage :image="item.image" />
        <h3>{{ item.title }}</h3>
        <div class="description">
          {{ item.description }}
        </div>
        <div class="price">CHF {{ item.price }}.—</div>
        <div class="buy"><span @click="order(index + 1)">Buy</span></div>
        <div
          :id="'paypal-button-container-' + (index + 1)"
          class="buttonContainer"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_SUBSCRIPTION } from "@/graphql/queries/getSubscription.js";
import GetImage from "@/components/GetImage.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "Subscription",
  data() {
    return {
      firstname: "",
      surname: "",
      company: "",
      address: "",
      city: "",
      postal_code: "",
      country: "",
      email: "",
      orderOpen1: false,
      orderOpen2: false
    };
  },
  apollo: {
    subscription: {
      query: GET_SUBSCRIPTION
    }
  },
  components: {
    GetImage,
    PageTitle
  },
  mounted() {
    this.$loadScript(
      "https://www.paypal.com/sdk/js?currency=CHF&client-id=AYIBcnqAoeNvyPLfw6Iu6zqiEOBHdPLZ_GMWnb4fK8DOSNTYgPqw1lmnMTGPcg1PVZeNFANaTmtKYuwk"
    )
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    order(no) {
      if (no == 1 && !this.orderOpen1) {
        this.initPayPalButton2();
        this.orderOpen1 = true;
      } else if (no == 2 && !this.orderOpen2) {
        this.initPayPalButton1();
        this.orderOpen2 = true;
      }
    },
    initPayPalButton1() {
      var price = this.subscription.subscriptionProduct[1].price;
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "white",
            layout: "vertical",
            label: "paypal"
          },

          createOrder: function(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  description: "1 Year (2 issues)",
                  amount: {
                    currency_code: "CHF",
                    value: price
                  }
                }
              ]
            });
          },

          onApprove: function(data, actions) {
            return actions.order.capture().then(function(details) {
              this.$router.push({
                name: "Subscription-successful",
                params: { details: details }
              });
            });
          },

          onError: function(err) {
            console.log(err);
          }
        })
        .render("#paypal-button-container-2");
    },
    initPayPalButton2() {
      var price = this.subscription.subscriptionProduct[0].price;
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "white",
            layout: "vertical",
            label: "paypal"
          },

          createOrder: function(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  description: "2 Years (4 issues)",
                  amount: { currency_code: "CHF", value: price }
                }
              ]
            });
          },

          onApprove: function(data, actions) {
            return actions.order.capture().then(function(details) {
              this.$router.push({ name: 'Subscription-successful', params: {details: details }});
            });
          },

          onError: function(err) {
            console.log(err);
          }
        })
        .render("#paypal-button-container-1");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/mixins.scss";
.subscription #issue-cover {
  // display: none;
}
#subscription {
  --line: 5px;
  @include desktop {
    --line: 10px;
  }
  margin: 0 10px;
  .subscription-products {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    .product {
      margin-bottom: 60px;
      img {
        height: 250px;
        width: auto;
      }
      .buy {
        text-transform: uppercase;
        margin: 1em 0;
        span {
          text-decoration: underline;
          text-decoration-color: var(--current-color);
          &:hover {
            text-decoration: none;
            color: var(--current-color);
          }
        }
      }
    }
    .buttonContainer {
      max-width: 400px;
    }
    @include desktop {
      flex-direction: row;
      .product {
        flex-basis: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
