<template>
  <img
    :v-if="image"
    :src="getFile(format.url)"
    :alt="image.alternativeText"
    :loading="loading"
    :width="format.width"
    :height="format.height"
  />
</template>

<script>
import { getFile } from "@/components/http.js";

export default {
  name: "GetImage",
  props: {
    image: Object,
    size: String,
    loading: String
  },
  computed: {
    format: function() {
      if (this.image.formats) {
        if (this.size == "large" && this.image.formats.large) {
          return this.image.formats.large;
        }
        if (this.size == "medium" && this.image.formats.medium) {
          return this.image.formats.medium;
        }
        if (this.size == "small" && this.image.formats.small) {
          return this.image.formats.small;
        } else return this.image;
      } else return this.image;
    }
  },
  methods: {
    getFile
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>