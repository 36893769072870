<template>
  <div class="vimeo-video">
    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe
        :src="'https://player.vimeo.com/video/' + embed"
        style="position:absolute;top:0;left:0;width:100%;height:100%;"
        frameborder="0"
        allow="autoplay; fullscreen"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowVideo",
  props: {
    embed: String
  },
  mounted() {
    this.$loadScript("https://player.vimeo.com/api/player.js")
      .then(() => {})
      .catch(() => {});
  }
}
</script>

<style lang="scss" scoped>

</style>