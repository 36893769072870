import gql from 'graphql-tag'

export const GET_PLAYLISTS = gql`
  query Playlists {
    playlists {
      id
      slug
      title
      title_1
      title_2
      title_3
      description
      created_at
      cover {
        id
        url
        width
        height
        alternativeText
        formats
      }
    }
  }
`