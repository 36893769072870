<template>
  <div class="playlist">
    <div class="tracks">
      <div class="cover">
        <div class="disc">
          <h3 ref="title" v-if="item.title_1">
            {{ item.title_1 }} <span>{{ item.title_2 }}</span>
            {{ item.title_3 }}
          </h3>
          <!-- <h3 ref="title" v-else>{{ item.title }}</h3> -->
          <GetImage :image="item.cover" loading="lazy" size="large" />
        </div>
      </div>
      <a
        class="track"
        v-for="track in item.track"
        :key="track.id"
        :href="track.url"
        target="blank"
      >
        <div class="artist">
          {{ track.artist }}
        </div>
        <div class="title">
          {{ track.title }}
        </div>
        <div class="label">
          {{ track.label }}
        </div>
      </a>
    </div>
    <div class="description" v-html="item.description" />
  </div>
</template>

<script>
import GetImage from "@/components/GetImage.vue";

export default {
  name: "Playlist",
  props: {
    item: Object
  },
  data() {
    return {}
  },
  components: {
    GetImage
  },
  methods: {
    spinDisc() {
      if (this.$el) {
        // const playlist = document.querySelector(".playlist");
        const playlist = this.$el;
        const disc = this.$el.querySelector(".disc");
        const top = this.$el.querySelector(".tracks").getBoundingClientRect().top;
        const bottom = this.$el.querySelector(".tracks").getBoundingClientRect().bottom;
        const center = (window.innerHeight - 30) / 2;
        // rotate the fucker when in view
        if (top <= window.innerHeight && bottom >= 0) {
          disc.style.transform = "rotate(" + ((top / 1.5) % 360) + "deg)";
        }
        // mobile classes for scroll effect
        if (top < 30 && bottom > window.innerHeight) {
          playlist.classList.add("coverTop");
          playlist.classList.remove("coverBottom");
        } else if (bottom <= window.innerHeight) {
          playlist.classList.remove("coverTop");
          playlist.classList.add("coverBottom");
        } else {
          playlist.classList.remove("coverTop");
          playlist.classList.remove("coverBottom");
        }
        // desktop classes for scroll effect
        if (top + 334 <= center && bottom - 394 > center) {
          playlist.classList.add("dtCoverCenter");
          playlist.classList.remove("dtCoverLeave");
        } else if (bottom - 394 <= center) {
          playlist.classList.remove("dtCoverCenter");
          playlist.classList.add("dtCoverLeave");
        } else {
          playlist.classList.remove("dtCoverCenter");
          playlist.classList.remove("dtCoverLeave");
        }
      }
    }
  },
  mounted() {
    // document.addEventListener("scroll", this.spinDisc);

    const pl = this;
    var observer = new IntersectionObserver(function(entries) {
      if (entries[0]["isIntersecting"] === true) {
        document.addEventListener("scroll", pl.spinDisc);
      } else {
        document.removeEventListener("scroll", pl.spinDisc);
      }
    });
    observer.observe(this.$el);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

.playlist {
  position: relative;
  .tracks {
    position: relative;
  }
  .cover {
    position: relative;
    padding: 10px;
    z-index: 99;
    background: black;
    h3 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 37px;
      line-height: 34px;
      letter-spacing: -0.01em;
      text-align: center;
      word-spacing: 100vw;
      padding-bottom: 0.15em;
      span {
        margin: 0.5em 0;
        display: block;
        width: 100%;
        font-size: 2em;
      }
      @include desktop {
        font-size: 60px;
        line-height: 51px;
        letter-spacing: -0.03em;
      }
    }
    img {
      height: auto;
      border-radius: 50%;
    }
    @include desktop {
      background: none;
      width: 728px;
      height: 728px;
      max-width: 80vw;
      max-height: 80vw;
      position: absolute;
      top: 0px;
      left: 50%;
      // transform: translate(-50%, -50%);
      transform: translate(-50%);
      pointer-events: none;
    }
  }
  @include mobile {
    &.coverTop {
      .tracks {
        padding-top: 100vw;
      }
      .cover {
        position: fixed;
        top: 45px;
        left: 0px;
      }
    }
    &.coverBottom {
      .tracks { padding-top: 100vw; }
      .cover {
        position: absolute;
        bottom: calc(var(--app-height) - calc(100vw + 29px));
      }
    }
  }
  @include desktop {
    &.dtCoverCenter {
      .cover {
        position: fixed;
        top: calc(50vh + 38px);
        // new
        transform: translate(-50%, -50%);
      }
    }
    &.dtCoverLeave {
      .cover {
        position: absolute;
        top: unset;
        bottom: 0px;
      }
    }
  }
  .track {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 3px solid var(--current-color);
    color: black;
    background: white;
    padding: 0 8px;
    font-size: 23px;
    line-height: 23px;
    letter-spacing: -0.03em;
    text-decoration: none;
    &:hover {
      color: black;
      @include desktop {
        background: var(--current-color);
        z-index: 200;
        border-bottom: 3px solid var(--current-color);
        margin-bottom: -3px;
        &:last-of-type {
          margin-bottom: 0px;
          border-bottom: none;
        }
      }
    }
    > * {
      flex-basis: 50%;
      flex-shrink: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include desktop {
        flex-basis: calc(100% / 3);
        font-size: 50px;
        line-height: 55px;
        letter-spacing: -0.03em;
        padding: 0 10px 30px;
      }
    }
    @include mobile {
      .artist {
        padding-right: 0.5em;
      }
      .title, .artist {
        padding-bottom: 14px;
        white-space: nowrap;
      }
      .label {
        flex-basis: 100%;
        padding-left: 50%;
        font-size: 12px;
        min-height: 23px;
      }
    }
  }
  .description {
    padding: 5px 10px 10px;
    font-family: timesnow, serif;
    color: black;
    background: white;
    border-top: 3px solid var(--current-color);
    font-size: 23px;
    line-height: 23px;
    letter-spacing: -0.01em;
    text-align: center;
    a {
      text-decoration-thickness: 3px;
    }
    @include desktop {
      font-size: 60px;
      line-height: 55px;
      letter-spacing: -0.02em;
    }
  }
}
</style>