import gql from 'graphql-tag'

export const GET_STOCKISTS = gql`
  query Stockists {
    stockists: stockist {
      countries {
        id
        name
        stockists {
          id
          name
          city
          address
          map_url
          web_url
        }
      }
    }
  }
`