import gql from 'graphql-tag'

export const GET_MENU = gql`
  query Menu {
    menu {
      id
      footer {
        id
        title
        url
        highlight
      }
      header {
        id
        title
        url
        highlight
      }
      burger_top {
        id
        title
        url
        highlight
      }
      burger_bottom {
        id
        title
        url
        highlight
      }
    }
  }
`