<template>
  <div class="slider-wrap">
    <div class="controls left" @click="moveSlide(false)" />
    <div class="controls right" @click="moveSlide()" />
    <div class="slider" ref="slider" @scroll="sliderScrolled()">
      <div class="slide" v-for="slide in slider.slides" :key="slide.id">
        <div class="slider-image">
          <GetImage :image="slide.image" loading="lazy" />
        </div>
        <div class="caption" v-html="slide.text"></div>
      </div>
      <div v-if="slider.textslide" v-html="slider.textslide"></div>
    </div>
    <div class="slide-index">
      <span class="current"></span>/<span class="total">{{ slider.slides.length }}</span>
    </div>
  </div>
</template>

<script>
import GetImage from "@/components/GetImage.vue";

export default {
  name: "Slider",
  data() {
    return {};
  },
  props: {
    slider: Object
  },
  components: {
    GetImage
  },
  methods: {
    slideWidths() {
      const element = this.$el;
      const allslides = this.$el.querySelectorAll(".slide");
      const firstslide = allslides[0];
      const lastslide = allslides[allslides.length - 1];
      element.style.setProperty(
        "--first-slide-width",
        `${firstslide.offsetWidth}px`
      );
      element.style.setProperty(
        "--last-slide-width",
        `${lastslide.offsetWidth}px`
      );
    },
    scrollSliderTo(elem) {
      const slider = this.$el.querySelector('.slider');
      const elemLeft = elem.offsetLeft;
      const elemWidth = elem.offsetWidth;
      const sliderWidth = slider.offsetWidth;
      const scrollLeft = elemLeft + 0.5 * (elemWidth - sliderWidth);
      slider.scrollTo({
        left: scrollLeft,
        behavior: "smooth"
      });
    },
    isCurrent(elem) {
      const gallery = this.$el.querySelector(".slider");
      const img = elem.querySelector("img");
      var elemLeft = img.offsetLeft;
      var elemWidth = img.offsetWidth;
      var sliderWidth = gallery.offsetWidth;
      var scrollLeft = elemLeft + 0.5 * (elemWidth - sliderWidth);
      return (
        gallery.scrollLeft >= scrollLeft - 100 &&
        gallery.scrollLeft <= scrollLeft + 100
      );
    },
    moveSlide($fwd = true) {
      var currents = this.$el.querySelectorAll(".slide-current");
      var current;
      const vm = this;
      currents.forEach(function(slide){
        if (vm.isCurrent(slide)) {
          current = slide;
        }
      });
      if ($fwd && current.nextElementSibling) {
        this.scrollSliderTo(current.nextElementSibling);
      } else if (!$fwd && current.previousElementSibling) {
        this.scrollSliderTo(current.previousElementSibling);
      }
    },
    sliderScrolled() {
      var currentIndex = this.$el.querySelector(".slide-index .current");
      var slides = this.$el.querySelectorAll(".slide");
      var current;
      const vm = this;
      slides.forEach(function(slide){
        if (vm.isCurrent(slide)) {
          current = slide;
        }
      });
      const idx = Array.from(slides).indexOf(current) + 1;
      if (current) {
        currentIndex.innerHTML = idx;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.slideWidths);
    this.slideWidths();
    this.sliderScrolled();

    // active slide
    const gallery = this.$el.querySelector(".slider");
    var items = Array.from(gallery.querySelectorAll(".slide"));

    const observer = new IntersectionObserver(onIntersectionObserved, {
      root: gallery,
      threshold: 0.1
    });

    items.forEach(item => {
      observer.observe(item);
    });

    function onIntersectionObserved(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-current");
        } else {
          setTimeout(function(){
            entry.target.classList.remove("slide-current");
          }, 500);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";
.slider-wrap {
  position: relative;
  overflow: hidden;
  .controls {
    @include mobile {
      display: none;
    }
    z-index: 100;
    height: 60vh;
    position: absolute;
    top: 0px;
    width: 50vw;
    cursor: pointer;
    @include desktop {
      height: 80vh;
    }
  }
  .left {
    left: 0px;
    cursor: url("../assets/images/arrow-b-l.svg") 47 28, w-resize;
  }
  .right {
    right: 0px;
    cursor: url("../assets/images/arrow-b-r.svg") 47 28, e-resize;
  }
  .slider {
    height: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 25px 0 25px 50px;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include desktop {
      padding: 36px 0 18px 36px;
      padding: 36px 0 18px 0;
      // padding: 36px 50vw 18px 50vw;
    }
    .slide {
      scroll-snap-align: center;
      width: calc(100vw - 100px);
      flex-grow: 0;
      margin: 0 25px 0 0;
      flex-shrink: 1;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:first-of-type {
        margin-left: calc(50vw - calc(0.5 * var(--first-slide-width)));
      }
      &:last-of-type {
        margin-right: calc(50vw - calc(0.5 * var(--last-slide-width)));
      }
      @include mobile {
        &:first-of-type {
          width: calc(100vw - 75px);
        }
        &:last-of-type {
          // padding-right: 50px;
          width: calc(100vw - 75px);
        }
      }
      @include desktop {
        width: auto;
        max-width: 100vw;
        margin: 0 36px;
        padding: 0 36px;
        &:last-of-type {
          padding-right: 108px;
          width: calc(100vw - 50px);
        }
      }
      .slide-image {
        width: max-content;
      }
      .caption {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        // height: 72px;
        padding: 1em 0;
        @include desktop {
          font-size: 16px;
          line-height: 18px;
        }
      }
      img {
        height: calc(60vh - 72px);
        width: calc(100vw - 100px);
        object-fit: contain;
        object-position: top center;
        max-width: unset;
        @include desktop {
          height: calc(80vh - 72px);
          width: auto;
          max-width: calc(100vw - 144px);
          object-position: top left;
        }
      }
    }
  }
  .slide-index {
    position: absolute;
    bottom: 12px;
    left: 48px;
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    @include desktop {
      bottom: 10px;
      left: 18px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}
</style>