<template>
  <div id="shopCart">
    <div id="modalClose">
      <img
        src="@/assets/images/x.svg"
        alt="Close"
        @click="$emit('cart-close')"
      />
    </div>
    <div id="cart">
      <div class="cart-entry" v-for="entry in cart" :key="entry.variant.id">
        <div class="amount">
          <span class="minus" @click="$emit('decrease', entry.variant)">−</span>
          <span class="actual-amount">{{ entry.amount }}</span>
          <span class="plus" @click="$emit('increase', entry.variant)">+</span>
        </div>
        <div class="collection">{{ entry.collection.title }}</div>
        <div class="product">{{ entry.product.title }}</div>
        <div class="variant">
          <span v-if="entry.product.variants.length > 1">
            {{ entry.variant.title }}</span>
        </div>
        <div class="price">{{ entry.variant.price.currencyCode }} {{ entry.variant.price.amount * entry.amount }}</div>
      </div>
      <div class="cart-total">
        <div class="subtotal-text">
          SUBTOTAL<br>Taxes and shipping calculated at checkout
        </div>
        <div class="subtotal">
          CHF<wbr> {{ subtotal.toFixed(2) }}
        </div>
      </div>
    </div>
    <button id="checkout" @click="$emit('checkout')">
      Checkout
    </button>
  </div>
</template>

<script>
export default {
  name: "ShopCart",
  data() {
    return {};
  },
  props: {
    cart: Array
  },
  computed: {
    subtotal: function () {
      var subtotal = 0;
      this.cart.forEach(entry => {
        subtotal += entry.amount * entry.variant.price.amount
      })
      return subtotal;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins.scss";

#shopCart {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--app-height);
  background: black;
  overflow: scroll;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  #modalClose {
    position: absolute;
    right: 12px;
    top: 12px;
    text-align: right;
    margin: 0 0 auto;
    cursor: pointer;
    img {
      @include mobile {
        height: 52px;
        width: 52px;
      }
      display: inline-block;
    }
  }
  #cart {
    > * {
      border-top: 2px solid white;
      padding: 10px 0;
    }
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .cart-entry {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;
      .collection {
        margin-bottom: .5em;
      }
      .product {
        margin-bottom: 1em;
      }
      .amount {
        position: absolute;
        top: 0;
        right: 0;
        .plus,
        .minus {
          color: var(--current-color);
          font-size: 50px;
          line-height: 50px;
          padding: 10px;
          cursor: pointer;
          user-select: none;
          font-weight: 300;
          &:active,
          &:hover {
            color: black;
            background: var(--current-color);
          }
        }
        .actual-amount {
          position: relative;
          top: -7px;
          left: 2px;
          display: inline-block;
          width: 20px;
          text-align: center;
        }
      }
    }
    .cart-total {
      padding: 75px 0 22px;
      border-bottom: 2px solid white;
      display: flex;
      flex-direction: row;
      .subtotal-text {
        text-align: right;
        padding-right: 10px;
      }
      .subtotal {
        text-align: right;
        width: 50px;
        margin-left: auto;
      }
    }
  }
  select,
  button {
    width: 100vw;
    margin: 0 -10px;
    padding: 15px 10px;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: .02em;
    text-transform: uppercase;
    border: none;
    background: var(--current-color);
    border-radius: 0px;
    flex-grow: 1;
    outline: none;
    box-shadow: none;
  }
  button {
    flex-grow: 0;
    margin: 0;
    margin-top: auto;
    color: white;
    background: black;
    display: block;
    width: 100%;
    height: auto;
    text-align: left;
    padding: 15px 0;
    position: initial;
    border-bottom: 2px solid white;
    &:hover {
      color: black;
    }
  }
  @include desktop {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: hidden;
    align-items: flex-end;
    #cart {
      margin-top: 160px;
      width: 100%;
      .cart-entry {
        flex-direction: row;
        flex-wrap: nowrap;
        height: 62px;
        padding: 0;
        align-items: center;
        > * {
          width: auto;
          flex-grow: 1;
          height: auto;
          padding: 5px 10px;
          align-items: center;
        }
        .amount {
          padding: 0;
          margin: 0;
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          flex-basis: 186px;
          flex-grow: 0;
          margin-right: 20px;
          > * {
            height: 100%;
            width: 62px !important;
            flex-shrink: 0;
          }
          .plus,
          .minus {
            height: 62px;
            font-size: 64px;
            line-height: 20px;
            text-align: center;
          }
          .actual-amount {
            top: -2px;
            left: 0px;
          }
        }
        .collection {
          margin: 0;
          flex-basis: 200px;
          flex-grow: 0;
        }
        .product {
          margin: 0;
          flex-basis: 400px;
          flex-grow: 0;
        }
        .variant {
          margin: 0;
        }
        .price {
          margin-left: auto;
          flex-basis: 100px;
          flex-grow: 0;
          padding-right: 0;
          text-align: right;
        }
      }
      .subtotal-text {
        flex-grow: 1;
      }
      .subtotal {
        margin-left: auto;
        flex-basis: 100px;
        flex-grow: 0;
        padding-right: 0;
        text-align: right;
      }
    }
    select {
      order: 5;
      width: calc(50% - 20px);
      flex-grow: 0;
      padding-left: 10px;
    }
    button {
      order: 6;
      width: auto;
      flex-grow: 0;
      padding-left: 10px;
      padding-right: 110px;
      margin: 50px 0 75px 0;
      flex-basis: auto;
      font-size: 45px;
      // font-weight: 400;
      letter-spacing: 0.02em;
    }
  }
}
</style>