var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$apollo.queries.collections.loading)?_c('div',{attrs:{"id":"shop"}},[_c('PageTitle',{attrs:{"title":"Shop"}}),_c('div',{staticClass:"products"},_vm._l((Object.values(
        _vm.collections.edges
      ).filter(collection => _vm.notHidden(collection.node.id))),function(collection){return _c('div',{key:'list-' + collection.node.id,class:[
        'collection',
        'collection-' + collection.node.handle,
        collection.node.id
      ]},_vm._l((Object.values(
          collection.node.products.edges
        ).filter(product => {
          return product.node.images.edges[0];
        })),function(product){return _c('div',{key:'product-' + product.node.id,class:[
          'product',
          { 'product-checkout': _vm.productIsInCart(product.node) },
          { soldout: !product.node.availableForSale }
        ],on:{"click":function($event){return _vm.modalOpen(product, collection)}}},[_c('div',{staticClass:"imagewrap"},[_c('img',{attrs:{"src":product.node.images.edges[0].node.src}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(product.node.title)+" ")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(product.node.descriptionHtml)}}),_c('div',{staticClass:"price"},[_vm._v(_vm._s(product.node.variants.edges[0].node.price.currencyCode)+" "+_vm._s(product.node.variants.edges[0].node.price.amount * 1))])])}),0)}),0),_c('div',{staticClass:"shop-menu"},[_c('div',{staticClass:"collections-expand",class:{ active: _vm.filterCollection.length > 0 },on:{"click":function($event){_vm.expandCollections = !_vm.expandCollections}}},[_vm._v(" Categories ")]),(_vm.collections)?_c('div',{staticClass:"collections",class:{ expanded: _vm.expandCollections }},[(_vm.collections.edges.length > 1)?_c('div',{class:[
          'menu-item',
          'menu-item-collection',
          { 'menu-item-active': _vm.activeFilter('all') }
        ],on:{"click":function($event){return _vm.filterBy()}}},[_vm._v(" All ")]):_vm._e(),_vm._l((_vm.collections.edges),function(collection){return _c('div',{key:'link-' + collection.node.id,class:[
          'menu-item',
          'menu-item-collection',
          { 'menu-item-active': _vm.activeFilter(collection.id) }
        ],on:{"click":function($event){return _vm.filterBy(collection.id)}}},[_vm._v(" "+_vm._s(collection.node.title)+" ")])})],2):_vm._e(),_c('div',{class:[
        'menu-item',
        'menu-item-checkout',
        { 'checkout-active': _vm.hasCart() }
      ],on:{"click":function($event){_vm.shopCart = true;}}},[_c('span',{staticClass:"checkout-button"},[_vm._v("Cart")]),_c('span',{class:['cart-amount', { 'highlight': _vm.cartHighlight }]},[_vm._v(_vm._s(this.cartLength()))])])]),(_vm.modalIsOpen)?_c('ShopModal',{attrs:{"product":_vm.modalProduct,"collection":_vm.modalCollection},on:{"add-to-bag":_vm.addToCart,"modal-close":_vm.modalClose}}):_vm._e(),(_vm.shopCart)?_c('ShopCart',{attrs:{"cart":this.$store.state.cart},on:{"checkout":this.doCheckout,"cart-close":this.cartClose,"increase":this.increaseAmount,"decrease":this.decreaseAmount}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }